export const TILE_SIZE = 32;
export const TORCH_TIME = 15000;
export const PLAYER_SPEED = 160;
export const TEMP_PORTAL_COOLDOWN = 10000;
export const NOTE_1 = "The Guide:\nTo the traveler who finds this note, know that the symbols you see are not of this world, yet they carry the sounds of our speech. Use this guide to unlock the meanings hidden within these strange letters. Let it be your key to understanding the messages left behind.";
export const NOTE_2 = "The Warning:\nBeware the shadows that crawl across the maze. They are not just tricks of the light. Stay within the path and keep the torch lit, for in darkness, the Minotaur's fury awakens.";
export const NOTE_3 = "The Lost Explorer:\nI have wandered these corridors for days, maybe weeks. The walls shift, the paths change, and yet I am drawn deeper. There is something here, something ancient and powerful, and I fear I may never leave.";
export const NOTE_4 = "The Minotaur's Origin:\nThe Minotaur is not just a beast but a guardian, bound to this labyrinth by forces long forgotten. It was created to protect something—or someone—hidden within these walls. But who is the real prisoner, the beast or us?";
export const NOTE_5 = "The Whispering Walls:\nAt night, when the wind howls through the maze, I hear whispers in the stone. They speak of an ancient pact, a deal made with darkness. I fear that if I stay too long, the walls will claim me as they have claimed others.";
export const NOTE_6 = "The Fallen Hero:\nOnce, a hero came to this maze, seeking to slay the Minotaur and break the curse. But even he fell to the labyrinth's tricks. His sword still lies somewhere within, a relic of his failed quest.";
export const NOTE_7 = "The Escape Plan:\nThere is a way out—I have seen it in my dreams. A door hidden deep within the maze, but it requires a key forged from the heart of the labyrinth itself. Seek the glowing stone, and it will guide you.";
export const NOTE_8 = "The Maze's Purpose:\nThis labyrinth is not merely a prison—it is a test. Those who enter are judged, not by their strength but by their will to survive. Only the worthy can find the true exit, and even fewer can decipher its secrets.";
export const NOTE_9 = "The Curse:\nThe curse of the maze was placed here by those who feared its power. They sealed away something terrible, but in doing so, they created a prison that feeds on the souls of the lost. I fear that by the time you read this, I will be one of them.";
export const NOTE_10 = "The Final Message:\nIf you are reading this, you have made it farther than most. The key is within your grasp, but be warned—once you unlock the final door, there is no turning back. What lies beyond is both the end and the beginning.";
