import Phaser from 'phaser';
export default class InventoryScene extends Phaser.Scene {
    player;
    inventoryItems;
    totalScore = 0;
    levelScore = 0;
    constructor() {
        super('InventoryScene');
        this.inventoryItems = {
            sword: { image: 'sword', count: 0 },
            shield: { image: 'shield', count: 0 },
            yarn: { image: 'yarn', count: 0 },
            key: { image: 'key', count: 0 },
            gold: { image: 'gold', count: 0 },
            map: { image: 'minimap', count: 0 }, // Add the minimap item
            magicScroll: { image: 'magicscroll', count: 0 }, // Add the magic scroll item
            torch: { image: 'torch', count: 0 }, // Add the torch item
            portalPotion: { image: 'portalpotion', count: 0 },
            notes: { image: 'note', count: 0 },
        };
    }
    init(data) {
        this.player = data.player;
        this.totalScore = data.totalScore;
        this.levelScore = data.levelScore;
    }
    create() {
        const { width, height } = this.scale;
        this.add.rectangle(0, 0, width, height, 0x000000, 0.5).setOrigin(0);
        this.updateInventoryDisplay();
        this.add.text(10, height - 50, `Level Score: ${this.levelScore}\nTotal Score: ${this.totalScore}`, {
            fontSize: '18px',
            color: '#fff'
        });
        this.input.keyboard?.on('keydown-I', this.toggleInventory, this);
        // Play the inventory open sound
        this.sound.play('inventoryopen');
        // Listen for inventory updates
        this.scene.get('MainScene').events.on('inventoryUpdated', this.updateInventoryDisplay, this);
    }
    updateInventoryDisplay() {
        const inventory = this.player.getInventory(); // Get the latest inventory state
        this.clearInventoryDisplay();
        let x = 50;
        let y = 50;
        const spacing = 70;
        // Display gold
        const goldCount = this.player.getGold();
        if (goldCount > 0) {
            const goldImage = this.add.image(x, y, this.inventoryItems.gold.image).setOrigin(0.5);
            this.add.text(goldImage.x, goldImage.y, `${goldCount}`, {
                fontSize: '16px',
                color: '#fff',
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
            }).setOrigin(0.5);
            x += spacing;
        }
        // Display key if the player has it
        if (this.player.hasKey) {
            const keyImage = this.add.image(x, y, this.inventoryItems.key.image).setOrigin(0.5);
            this.add.text(keyImage.x, keyImage.y, '1', {
                fontSize: '16px',
                color: '#fff',
                backgroundColor: 'rgba(0, 0, 0, 0.7)'
            }).setOrigin(0.5);
            x += spacing;
        }
        // Display other inventory items
        Object.keys(this.inventoryItems).forEach((item) => {
            if (item === 'gold' || item === 'key')
                return; // Skip gold and key here
            const count = inventory[item] || 0;
            if (count > 0) {
                const itemData = this.inventoryItems[item];
                const itemImage = this.add.image(x, y, itemData.image).setOrigin(0.5);
                this.add.text(itemImage.x, itemImage.y, `${count}`, {
                    fontSize: '16px',
                    color: '#fff',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)'
                }).setOrigin(0.5);
                x += spacing;
                if (x > this.scale.width - 50) {
                    x = 50;
                    y += spacing;
                }
            }
        });
    }
    clearInventoryDisplay() {
        this.children.each(child => child.destroy());
    }
    toggleInventory() {
        // Play the inventory close sound
        this.sound.play('inventoryclose');
        this.scene.stop('InventoryScene');
    }
}
