import Phaser from 'phaser';
import Notes from '../entities/Notes';
export default class NoteReadingScene extends Phaser.Scene {
    notes = [];
    currentNoteIndex = 0;
    noteTextObject = null;
    notepadTextObject = null;
    notepadInput = '';
    savedTranslations = [];
    bookSound = null;
    narrationSound = null;
    notepadInputActive = false;
    noteKey; // To track which note is currently being viewed
    narrationPlaying = false; // Track if narration is playing
    notepadVisible = false; // Track if the notepad is visible
    defaultNote = `
    Key Bindings:
- W, A, S, D or Arrow Keys: Move your character
- B: Open and close this notebook
- N: Go to the next note
- V: Go to the previous note
- I: Open inventory
- M: Toggle minimap
- Y: Activate yarn trail
- T: Use a torch
- P: Activate portal potion

Objective:
- Find the key to unlock the exit.
- Avoid the Minotaur and other dangers.
- Collect items to help you survive.
- Use portals and other tools to navigate the maze.
- Look out for notes that reveal the story and clues.
`;
    addButton;
    saveButton;
    closeButton;
    playButton;
    pauseButton;
    stopButton;
    showNotepadButton; // Button to show the notepad
    constructor() {
        super('NoteReadingScene');
    }
    init(data) {
        console.log("Initializing NoteReadingScene with notes:", data.notes, "and noteKey:", data.noteKey);
        this.notes = data.notes.map(note => note.content);
        this.notes.unshift(this.defaultNote);
        this.noteKey = data.noteKey;
        const noteContentData = Notes.getNotesContent()[this.noteKey];
        if (!noteContentData) {
            console.error(`No content found for noteKey: ${this.noteKey}`);
            return;
        }
        this.currentNoteIndex = this.notes.findIndex(note => note === noteContentData.content);
        this.savedTranslations = new Array(this.notes.length).fill('');
    }
    create() {
        const background = this.add.graphics();
        background.fillStyle(0x000000, 0.7);
        background.fillRect(0, 0, this.scale.width, this.scale.height);
        this.pauseAllSounds();
        this.bookSound = this.sound.add('bookscene', {
            loop: true,
            volume: 1
        });
        this.bookSound.play();
        this.noteTextObject = this.add.text(50, 50, '', {
            fontFamily: 'Verdana',
            fontSize: '20px',
            color: '#ffffff',
            wordWrap: { width: this.scale.width - 150 } // Wider text area
        });
        this.createButtons(50, this.scale.height - 100); // Adjusted position
        this.fadeInText(this.notes[this.currentNoteIndex]);
        this.input.keyboard?.on('keydown', this.handleNotepadInput, this);
        this.createKeyIndicators();
        this.createPhoneticGuide();
        this.setupKeyBindings();
        // Play narration when note is opened
        this.playNarration();
    }
    pauseAllSounds() {
        this.sound.pauseAll();
    }
    unpauseAllSounds() {
        this.sound.resumeAll();
    }
    fadeInText(text) {
        if (this.noteTextObject) {
            this.noteTextObject.setText(text);
            this.noteTextObject.alpha = 0;
            this.tweens.add({
                targets: this.noteTextObject,
                alpha: 1,
                duration: 1000,
                ease: 'Power2'
            });
        }
    }
    createButtons(x, y) {
        this.playButton = this.add.text(x, y, '[Play]', {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
            backgroundColor: '#000000',
            padding: { left: 5, right: 5, top: 2, bottom: 2 }
        }).setInteractive();
        this.pauseButton = this.add.text(x + 100, y, '[Pause]', {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
            backgroundColor: '#000000',
            padding: { left: 5, right: 5, top: 2, bottom: 2 }
        }).setInteractive();
        this.stopButton = this.add.text(x + 200, y, '[Stop]', {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
            backgroundColor: '#000000',
            padding: { left: 5, right: 5, top: 2, bottom: 2 }
        }).setInteractive();
        this.showNotepadButton = this.add.text(x + 300, y, '[Notepad]', {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
            backgroundColor: '#000000',
            padding: { left: 5, right: 5, top: 2, bottom: 2 }
        }).setInteractive();
        this.closeButton = this.add.text(this.scale.width - 100, y, '[Close]', {
            fontFamily: 'Arial',
            fontSize: '18px',
            color: '#ffffff',
            backgroundColor: '#000000',
            padding: { left: 5, right: 5, top: 2, bottom: 2 }
        }).setInteractive();
        this.playButton.on('pointerdown', this.playNarration, this);
        this.pauseButton.on('pointerdown', this.pauseNarration, this);
        this.stopButton.on('pointerdown', this.stopNarration, this);
        this.showNotepadButton.on('pointerdown', this.toggleNotepad, this); // Toggle notepad visibility
        this.closeButton.on('pointerdown', this.closeScene, this);
    }
    playNarration() {
        if (!this.narrationSound) {
            const noteData = Notes.getNotesContent()[this.noteKey];
            if (noteData) {
                this.narrationSound = this.sound.add(noteData.narration);
            }
        }
        if (this.narrationSound && !this.narrationPlaying) {
            this.narrationSound.play();
            this.narrationPlaying = true;
        }
        else if (this.narrationSound && this.narrationSound.isPaused) {
            this.narrationSound.resume();
        }
    }
    pauseNarration() {
        if (this.narrationSound && this.narrationPlaying) {
            this.narrationSound.pause();
        }
    }
    stopNarration() {
        if (this.narrationSound) {
            this.narrationSound.stop();
            this.narrationPlaying = false;
        }
    }
    toggleNotepad() {
        this.notepadVisible = !this.notepadVisible;
        if (this.notepadVisible) {
            this.showNotepad();
        }
        else {
            this.hideNotepad();
        }
    }
    showNotepad() {
        if (!this.notepadTextObject) {
            const notepadX = this.scale.width / 2 + 50;
            const notepadY = this.scale.height - 400;
            const notepadWidth = this.scale.width / 2 - 100;
            this.add.rectangle(notepadX - 10, notepadY - 10, notepadWidth + 20, 100, 0xffffff).setOrigin(0);
            this.notepadTextObject = this.add.text(notepadX, notepadY, this.notepadInput, {
                fontFamily: 'Verdana',
                fontSize: '20px',
                color: '#ffffff',
                wordWrap: { width: notepadWidth },
                backgroundColor: '#333333',
                padding: { left: 10, right: 10, top: 5, bottom: 5 }
            });
        }
    }
    hideNotepad() {
        if (this.notepadTextObject) {
            this.notepadTextObject.destroy();
            this.notepadTextObject = null;
        }
    }
    handleNotepadInput(event) {
        if (!this.notepadInputActive)
            return;
        if (event.key === 'Backspace') {
            this.notepadInput = this.notepadInput.slice(0, -1);
        }
        else if (event.key.length === 1) {
            this.notepadInput += event.key;
        }
        if (this.notepadTextObject) {
            this.notepadTextObject.setText(this.notepadInput);
        }
    }
    createKeyIndicators() {
        const indicators = [
            { key: 'B', action: 'Close' },
            { key: 'N', action: 'Next' },
            { key: 'V', action: 'Previous' }
        ];
        let y = this.scale.height - 50;
        indicators.forEach((indicator, index) => {
            this.add.text(100 + index * 150, y, `[${indicator.key}] ${indicator.action}`, {
                fontFamily: 'Arial',
                fontSize: '18px',
                color: '#ffffff'
            });
        });
    }
    createPhoneticGuide() {
        const guideData = [
            { english: 'A', shavian: '𐑩' }, { english: 'B', shavian: '𐑚' }, { english: 'C', shavian: '𐑓' }, { english: 'D', shavian: '𐑛' },
            { english: 'E', shavian: '𐑧' }, { english: 'F', shavian: '𐑓' }, { english: 'G', shavian: '𐑜' }, { english: 'H', shavian: '𐑣' },
            { english: 'I', shavian: '𐑦' }, { english: 'J', shavian: '𐑛' }, { english: 'K', shavian: '𐑒' }, { english: 'L', shavian: '𐑤' },
            { english: 'M', shavian: '𐑥' }, { english: 'N', shavian: '𐑙' }, { english: 'O', shavian: '𐑪' }, { english: 'P', shavian: '𐑖' },
            { english: 'Q', shavian: '𐑒' }, { english: 'R', shavian: '𐑮' }, { english: 'S', shavian: '𐑕' }, { english: 'T', shavian: '𐑑' },
            { english: 'U', shavian: '𐑫' }, { english: 'V', shavian: '𐑝' }, { english: 'W', shavian: '𐑢' }, { english: 'X', shavian: '𐑒' },
            { english: 'Y', shavian: '𐑘' }, { english: 'Z', shavian: '𐑟' }
        ];
        const startX = this.scale.width - 300;
        const startY = 50;
        const rowHeight = 30;
        const columnSpacing = 120;
        guideData.forEach((item, index) => {
            const x = startX + (index % 2) * columnSpacing;
            const y = startY + Math.floor(index / 2) * rowHeight;
            this.add.text(x, y, `${item.english} -> ${item.shavian}`, {
                fontFamily: 'Arial',
                fontSize: '22px',
                color: '#ffffff',
                align: 'left'
            });
        });
    }
    setupKeyBindings() {
        this.input.keyboard?.on('keydown-B', this.closeScene, this);
        this.input.keyboard?.on('keydown-N', this.showNextNote, this);
        this.input.keyboard?.on('keydown-V', this.showPreviousNote, this);
    }
    disableKeyBindings() {
        this.input.keyboard?.off('keydown-N');
        this.input.keyboard?.off('keydown-V');
        this.input.keyboard?.off('keydown-B');
    }
    enableKeyBindings() {
        this.setupKeyBindings();
    }
    showNextNote() {
        this.saveCurrentTranslation();
        this.currentNoteIndex = (this.currentNoteIndex + 1) % this.notes.length;
        this.notepadInput = this.savedTranslations[this.currentNoteIndex];
        this.updateNoteAndNotepad();
        this.sound.play('pageturn');
    }
    showPreviousNote() {
        this.saveCurrentTranslation();
        this.currentNoteIndex = (this.currentNoteIndex - 1 + this.notes.length) % this.notes.length;
        this.notepadInput = this.savedTranslations[this.currentNoteIndex];
        this.updateNoteAndNotepad();
        this.sound.play('pageturn2');
    }
    updateNoteAndNotepad() {
        this.fadeInText(this.notes[this.currentNoteIndex]);
        if (this.notepadTextObject) {
            this.notepadTextObject.setText(this.notepadInput);
        }
    }
    saveCurrentTranslation() {
        this.savedTranslations[this.currentNoteIndex] = this.notepadInput;
        this.closeNotepadInput();
    }
    closeNotepadInput() {
        this.notepadInputActive = false;
        this.notepadTextObject?.setBackgroundColor('#333333');
        this.enableKeyBindings();
    }
    closeScene() {
        this.saveCurrentTranslation();
        if (this.bookSound) {
            this.bookSound.stop();
        }
        this.stopNarration();
        this.unpauseAllSounds();
        if (this.scene.isPaused('MainScene')) {
            this.scene.resume('MainScene');
        }
        else if (this.scene.isPaused('LevelPickingScene')) {
            this.scene.resume('LevelPickingScene');
        }
        this.scene.stop('NoteReadingScene');
    }
}
