import Phaser from 'phaser';
import BootScene from './scenes/BootScene';
import MainScene from './scenes/MainScene';
import InventoryScene from './scenes/InventoryScene';
import StoreScene from './scenes/StoreScene';
import MainMenuScene from './scenes/MainMenuScene';
import OptionsScene from './scenes/OptionScene';
import GameOverScene from './scenes/GameOverScene';
import LevelCompleteScene from './scenes/LevelCompleteScene';
import PhaserRaycaster from 'phaser-raycaster';
import MinimapScene from './scenes/MinimapScene';
import StoryScene from './scenes/StoryScene';
import InstructionsScene from './scenes/InstructionsScene';
import JoystickScene from './scenes/JoystickScene'; // Import the JoystickScene
import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin.js';
import LevelPickingScene from './scenes/LevelPickingScene';
import GameCompleteScene from './scenes/GameCompleteScene';
import NoteReadingScene from './scenes/NoteReadingScene';
const config = {
    type: Phaser.AUTO,
    width: window.innerWidth,
    height: window.innerHeight,
    scene: [
        BootScene,
        MainMenuScene,
        InstructionsScene,
        MainScene,
        InventoryScene,
        StoreScene,
        GameOverScene,
        LevelCompleteScene,
        OptionsScene,
        MinimapScene,
        StoryScene,
        JoystickScene, // Add the JoystickScene to the list of scenes
        LevelPickingScene,
        GameCompleteScene,
        NoteReadingScene,
    ],
    physics: {
        default: 'arcade',
        arcade: {
            debug: false,
            debugShowBody: false,
            debugShowStaticBody: false
        }
    },
    plugins: {
        global: [
            {
                key: 'rexVirtualJoystick',
                plugin: VirtualJoystickPlugin,
                start: true
            }
        ],
        scene: [
            {
                key: 'PhaserRaycaster',
                plugin: PhaserRaycaster,
                mapping: 'raycasterPlugin'
            }
        ]
    }
};
const game = new Phaser.Game(config);
window.addEventListener('resize', () => {
    game.scale.resize(window.innerWidth, window.innerHeight);
});
