import Phaser from 'phaser';
import Sounds from '../utils/Sounds'; // Import Sounds class
export default class GameOverScene extends Phaser.Scene {
    gameOverMusic;
    totalScore;
    player; // Reference to the player
    sounds; // Reference to the Sounds instance
    constructor() {
        super({ key: 'GameOverScene' });
    }
    init(data) {
        this.totalScore = data.totalScore;
        if (data.player) {
            this.player = data.player; // Set the player reference if passed
        }
    }
    create() {
        const { width, height } = this.scale;
        // Initialize Sounds
        this.sounds = new Sounds(this);
        this.cameras.main.setBackgroundColor('rgba(0, 0, 0, 0.5)');
        this.cameras.main.fadeIn(8000);
        this.add.text(width / 2, height / 2 - 50, 'Game Over', {
            fontSize: '48px',
            color: '#fff'
        }).setOrigin(0.5);
        this.add.text(width / 2, height / 2 - 100, `Total Score: ${this.totalScore}`, {
            fontSize: '32px',
            color: '#fff'
        }).setOrigin(0.5);
        const newGameButton = this.add.text(width / 2, height / 2 + 50, 'New Game', {
            fontSize: '32px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setPadding(10).setInteractive();
        newGameButton.on('pointerdown', () => {
            this.sound.stopAll();
            this.resetGameState(); // Reset game state when starting a new game
            this.scene.start('MainMenuScene');
        });
        // Add and play game over music
        this.gameOverMusic = this.sound.add('gameover', {
            loop: true,
            volume: .75
        });
        this.gameOverMusic.play();
        // Play Game Over Narration with a 2-second delay
        this.time.delayedCall(2000, () => {
            this.sounds.playGameOverNarration();
        });
        const additionalText = this.add.text(width / 2, height / 2 + 150, "The Minotaur's roar echoes through the labyrinth. Your journey ends here, lost in the maze's dark embrace. The secrets remain hidden, and the beast still reigns supreme.", {
            fontSize: '20px',
            color: '#fff',
            align: 'center',
            wordWrap: { width: width - 40 }
        }).setOrigin(0.5).setAlpha(0);
        this.tweens.add({
            targets: additionalText,
            alpha: 1,
            duration: 8000,
            ease: 'Power2'
        });
    }
    resetGameState() {
        // Assuming you have a reference to the player instance
        if (this.player) {
            this.player.resetAll(); // Call resetAll on the player
        }
        // Reset all relevant registry keys
        this.registry.set('totalScore', 0);
        this.registry.set('currentLevel', 1);
        this.registry.set('score', 0);
        this.registry.set('completedLevels', []);
        this.registry.set('playerData', { gold: 0, inventory: {}, collectedNotes: [] });
        this.registry.set('level', 1);
        this.registry.set('maze', null);
        // Reset collected notes
        this.registry.set('collectedNotes', []);
        // Stop all sounds
        this.sound.stopAll();
        // Clear timers and events
        this.time.removeAllEvents();
        // Ensure all relevant scenes are stopped before restarting
        this.scene.stop('MainScene');
        this.scene.stop('LevelPickingScene');
        this.scene.stop('JoystickScene');
        this.scene.stop('MinimapScene');
        console.log('Game state has been reset');
    }
}
