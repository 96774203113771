import Phaser from 'phaser';
export default class InstructionsScene extends Phaser.Scene {
    constructor() {
        super('InstructionsScene');
    }
    create() {
        const { width, height } = this.scale;
        // Add semi-transparent background
        this.add.rectangle(0, 0, width, height, 0x000000, 0.7).setOrigin(0);
        // Add title
        this.add.text(width / 2, height * 0.1, 'Instructions', {
            fontSize: '48px', // Increased font size for better visibility
            color: '#ffffff',
            fontStyle: 'bold', // Added bold style for emphasis
        }).setOrigin(0.5);
        // Define instructions text
        const instructions = `
        Game Objective:
        Navigate the maze, collect items, and avoid the Minotaur.
        Use the portals and tools at your disposal to reach the exit.

        Controls:
        - WASD or Arrow Keys: Move
        - I: Open Inventory
        - E: Interact/Enter Store
        - M: Toggle Minimap
        - T: Activate Torch (extends LOS)
        - Y: Toggle Yarn Trail
        - P: Use Portal Potion - Gold Potion (lasts for 10 seconds)
        `;
        // Add instructions text with word wrap
        this.add.text(width * 0.1, height * 0.25, instructions, {
            fontSize: '24px', // Adjusted font size for readability
            color: '#ffffff',
            wordWrap: { width: width * 0.8 },
            align: 'left',
            lineSpacing: 10, // Added line spacing for better text separation
        });
        // Add Back button with hover effect
        const backButton = this.add.text(width / 2, height * 0.85, 'Back', {
            fontSize: '32px', // Increased font size for emphasis
            color: '#ffffff',
            backgroundColor: '#333333', // Changed background color for better contrast
            padding: { x: 20, y: 10 }, // Added padding for a button-like appearance
        }).setOrigin(0.5).setInteractive();
        backButton.on('pointerdown', () => {
            this.scene.start('MainMenuScene');
        });
        backButton.on('pointerover', () => {
            backButton.setStyle({ backgroundColor: '#555555' }); // Lighten the background on hover
        });
        backButton.on('pointerout', () => {
            backButton.setStyle({ backgroundColor: '#333333' }); // Revert to original color
        });
    }
}
