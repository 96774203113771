import Phaser from 'phaser';
export class GlobalSoundManager {
    scene;
    backgroundSounds = [];
    itemSounds = [];
    narrationSounds = [];
    isNarrationPlaying = false;
    constructor(scene) {
        this.scene = scene;
    }
    // Adds a sound to the appropriate list based on its type
    addSound(key, type) {
        const sound = this.scene.sound.add(key);
        switch (type) {
            case 'background':
                this.backgroundSounds.push(sound);
                break;
            case 'item':
                this.itemSounds.push(sound);
                break;
            case 'narration':
                this.narrationSounds.push(sound);
                break;
        }
        return sound;
    }
    // Plays a sound from the appropriate list with an optional volume parameter
    playSound(key, type, options = {}) {
        const volume = options.volume ?? 1.0; // Default volume is 1.0 (100%)
        switch (type) {
            case 'background':
                const backgroundSound = this.getSound(key, this.backgroundSounds);
                if (backgroundSound) {
                    this.setSoundVolume(backgroundSound, volume);
                    backgroundSound.play();
                }
                break;
            case 'item':
                const itemSound = this.getSound(key, this.itemSounds);
                if (itemSound) {
                    this.setSoundVolume(itemSound, volume);
                    itemSound.play();
                }
                break;
            case 'narration':
                if (!this.isNarrationPlaying) {
                    const narrationSound = this.getSound(key, this.narrationSounds);
                    if (narrationSound) {
                        console.log(`Playing narration: ${key}`);
                        this.isNarrationPlaying = true;
                        this.setSoundVolume(narrationSound, volume);
                        narrationSound.play();
                        narrationSound.once('complete', () => {
                            console.log(`Narration complete: ${key}`);
                            this.isNarrationPlaying = false;
                        });
                    }
                    else {
                        console.log(`Narration sound not found: ${key}`);
                    }
                }
                else {
                    console.log(`Narration already playing: ${key} won't play`);
                }
                break;
        }
    }
    // Stops a specific sound from playing
    stopSound(key, type) {
        const sound = this.getSound(key, this.getSoundList(type));
        sound?.stop();
    }
    // Stops all sounds of all types
    stopAllSounds() {
        this.backgroundSounds.forEach(sound => sound.stop());
        this.itemSounds.forEach(sound => sound.stop());
        this.narrationSounds.forEach(sound => sound.stop());
        this.isNarrationPlaying = false;
    }
    getSound(key, soundList) {
        return soundList.find(sound => sound.key === key);
    }
    getSoundList(type) {
        switch (type) {
            case 'background':
                return this.backgroundSounds;
            case 'item':
                return this.itemSounds;
            case 'narration':
                return this.narrationSounds;
            default:
                return [];
        }
    }
    setSoundVolume(sound, volume) {
        if (sound instanceof Phaser.Sound.WebAudioSound || sound instanceof Phaser.Sound.HTML5AudioSound) {
            sound.setVolume(volume);
        }
    }
}
