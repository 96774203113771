import Phaser from 'phaser';
import { TILE_SIZE } from '../utils/constants';
import { shuffleArray } from '../utils/helpers';
export default class Items {
    scene;
    player;
    occupiedPositions;
    goldGroup;
    chestGroup;
    sounds;
    maxChests = 5; // Maximum number of chests
    permanentlyVisibleItems; // Track items that are permanently visible
    constructor(scene, player, occupiedPositions, sounds) {
        this.scene = scene;
        this.player = player;
        this.occupiedPositions = occupiedPositions;
        this.sounds = sounds;
        this.goldGroup = this.scene.physics.add.group();
        this.chestGroup = this.scene.physics.add.group();
        this.permanentlyVisibleItems = new Set();
    }
    addItems(deadEnds) {
        const requiredDeadEnds = 2; // Minimum required dead ends for entrance and exit
        // Ensure there are enough dead ends for chests after entrance, exit, and portals
        if (deadEnds.length > requiredDeadEnds + 2) {
            // Add chests to dead ends, up to the maximum number of chests
            shuffleArray(deadEnds).slice(requiredDeadEnds + 2, requiredDeadEnds + 2 + this.maxChests).forEach((pos) => {
                const chest = this.scene.physics.add.sprite(pos.x * TILE_SIZE, pos.y * TILE_SIZE, 'chest');
                chest.setOrigin(0, 0);
                chest.setAlpha(0); // Make chest invisible initially
                chest.setData('opened', false); // Set the opened flag to false
                this.chestGroup.add(chest);
                this.chestGroup.setDepth(0);
                this.occupiedPositions.add(`${pos.x},${pos.y}`);
                this.scene.physics.add.overlap(this.player, chest, this.openChest, undefined, this);
            });
        }
        // Add portal potion
        const portalPotionPos = this.findRandomOpenTile();
        if (portalPotionPos) {
            const portalPotion = this.scene.physics.add.sprite(portalPotionPos.x * TILE_SIZE, portalPotionPos.y * TILE_SIZE, 'portalpotion');
            portalPotion.setOrigin(0, 0);
            portalPotion.setAlpha(0); // Make potion invisible initially
            this.occupiedPositions.add(`${portalPotionPos.x},${portalPotionPos.y}`);
            this.scene.physics.add.overlap(this.player, portalPotion, this.collectPortalPotion, undefined, this);
        }
        // Add random gold pieces to the map
        for (let i = 0; i < 15; i++) { // Adjust the number of gold pieces as needed
            const pos = this.findRandomOpenTile();
            if (pos) {
                const gold = this.scene.physics.add.sprite(pos.x * TILE_SIZE, pos.y * TILE_SIZE, 'gold');
                gold.setOrigin(0, 0);
                gold.setAlpha(0); // Make gold invisible initially
                this.goldGroup.add(gold);
                this.occupiedPositions.add(`${pos.x},${pos.y}`);
                this.scene.physics.add.overlap(this.player, gold, this.collectGold, undefined, this);
            }
        }
    }
    collectPortalPotion(player, portalPotion) {
        portalPotion.destroy();
        this.player.addItem('portalPotion'); // Assuming addItem handles adding portal potions to inventory
        this.sounds.playYarnPickupSound(); // Play pickup sound
        this.sounds.playRandomPotionSound();
    }
    openChest(player, chest) {
        if (this.player.hasKey && !chest.getData('opened')) {
            chest.setTexture('chestopen');
            this.player.addScore(25); // Add 15 points to the player's score
            this.player.addGold(25);
            this.sounds.playChestOpenSound(); // Correct method to play chest open sound
            this.sounds.playRandomGoldSound();
            chest.setData('opened', true); // Set the opened flag to true
        }
    }
    collectGold(player, gold) {
        gold.destroy();
        this.player.addScore(5); // Add 5 points to the player's score
        this.player.addGold(5);
        this.sounds.playCoinPickupSound();
        this.sounds.playRandomGoldSound();
    }
    findRandomOpenTile() {
        let pos;
        do {
            pos = {
                x: Phaser.Math.Between(1, this.scene.physics.world.bounds.width / TILE_SIZE - 2),
                y: Phaser.Math.Between(1, this.scene.physics.world.bounds.height / TILE_SIZE - 2)
            };
        } while (this.occupiedPositions.has(`${pos.x},${pos.y}`) || !this.isTileOpen(pos));
        return pos;
    }
    isTileOpen(pos) {
        const tileX = Math.floor(pos.x);
        const tileY = Math.floor(pos.y);
        if (tileX < 0 ||
            tileX >= this.scene.physics.world.bounds.width / TILE_SIZE ||
            tileY < 0 ||
            tileY >= this.scene.physics.world.bounds.height / TILE_SIZE) {
            return false;
        }
        const tile = this.scene.children.getByName(`tile-${tileX}-${tileY}`);
        return this.scene.physics.world.bounds.contains(tileX * TILE_SIZE, tileY * TILE_SIZE) &&
            !this.occupiedPositions.has(`${tileX},${tileY}`) &&
            tile !== null && tile !== undefined;
    }
    updateVisibility(fovTiles) {
        const updateObjectVisibility = (object) => {
            if (object) {
                const tileX = Math.floor(object.x / TILE_SIZE);
                const tileY = Math.floor(object.y / TILE_SIZE);
                const itemKey = `${tileX},${tileY}`;
                if (this.player.hasMagicScroll && fovTiles[tileY] && fovTiles[tileY][tileX]) {
                    this.permanentlyVisibleItems.add(itemKey);
                    object.setAlpha(1);
                }
                else if (fovTiles[tileY] && fovTiles[tileY][tileX]) {
                    object.setAlpha(1);
                }
                else if (this.permanentlyVisibleItems.has(itemKey)) {
                    object.setAlpha(1);
                }
                else {
                    object.setAlpha(0);
                }
            }
        };
        this.goldGroup.getChildren().forEach((gold) => {
            updateObjectVisibility(gold);
        });
        this.chestGroup.getChildren().forEach((chest) => {
            updateObjectVisibility(chest);
        });
    }
}
