import Phaser from 'phaser';
import { Mrpas } from 'mrpas';
import Player from '../entities/Player';
import Minotaur from '../entities/Minotaur';
import Portals from '../entities/Portals';
import EntranceExit from '../entities/EntranceExit';
import Yarn from '../entities/Yarn';
import Items from '../entities/Items';
import Notes from '../entities/Notes';
import MazeGenerator from '../systems/MazeGenerator';
import { findDeadEnds } from '../utils/helpers';
import { TILE_SIZE, TORCH_TIME, } from '../utils/constants';
import Sounds from '../utils/Sounds';
import MagicScroll from '../entities/MagicScroll';
import MinimapItem from '../entities/MinimapItem';
import Torch from '../entities/Torch';
import MinimapScene from './MinimapScene';
import Pathfinding from 'pathfinding'; // Import the pathfinding library
export default class MainScene extends Phaser.Scene {
    player;
    playerLight;
    minotaur;
    mazeGenerator;
    walls;
    portals;
    entranceExit;
    yarn;
    items;
    notes; // Reference to the Notes class
    occupiedPositions = new Set();
    soundtrack;
    isPlaying = true;
    maze;
    los;
    fovTiles;
    permanentVisibleTiles;
    sounds;
    hud;
    level = 1;
    inventoryVisible = false;
    magicScroll;
    minimap;
    minimapVisible = false;
    yarnActive = false;
    torchCount = 0;
    trailGraphics;
    trail = [];
    fullTrail = [];
    trailLifetime = 5000;
    isFullVisibility = false;
    doorPosition;
    darknessOverlay;
    torchTimers = [];
    // Variables for touch controls and pathfinding
    grid; // The grid representation for pathfinding
    path; // The path the player will follow
    targetPosition; // The target position for movement
    debugGraphics; // For debugging the path (optional)
    constructor() {
        super('MainScene');
        this.mazeGenerator = new MazeGenerator(20, 20);
        this.fovTiles = [];
        this.permanentVisibleTiles = [];
    }
    init(data) {
        // Initialize sounds
        this.sounds = new Sounds(this); // Ensure this happens before Player is created
        if (data.level !== undefined) {
            this.level = data.level;
        }
        if (data.totalScore !== undefined) {
            this.registry.set('totalScore', data.totalScore);
        }
        const playerData = data.playerData || { gold: 0, inventory: {}, collectedNotes: [] };
        this.player = new Player(this, 0, 0, 'player', this.sounds, playerData); // Pass the sounds instance
        // Only initialize collectedNotes in registry if not already set
        if (!this.registry.get('collectedNotes')) {
            this.registry.set('collectedNotes', playerData.collectedNotes || []);
        }
    }
    create() {
        this.sounds = new Sounds(this);
        const gameWidth = this.scale.width;
        const gameHeight = this.scale.height;
        const numRows = Math.ceil(gameHeight / TILE_SIZE);
        const numCols = Math.ceil(gameWidth / TILE_SIZE);
        this.mazeGenerator = new MazeGenerator(numCols, numRows);
        this.maze = this.mazeGenerator.generate();
        this.registry.set('maze', this.maze);
        this.walls = this.physics.add.staticGroup();
        this.createGrid(); // Create grid for pathfinding
        this.events.on('spawnTemporaryPortal', () => {
            this.portals.spawnTemporaryPortal();
        });
        this.events.on('playerStartedKeyboardMovement', this.clearPath, this);
        this.soundtrack = this.sound.add('soundtrack', {
            loop: true,
            volume: 1,
        });
        if (!this.scene.get('MinimapScene')) {
            this.scene.add('MinimapScene', MinimapScene, false);
        }
        this.soundtrack.play();
        const tileSize = TILE_SIZE;
        for (let y = 0; y < this.maze.length; y++) {
            for (let x = 0; x < this.maze[y].length; x++) {
                if (this.maze[y][x] === 1) {
                    const wall = this.walls.create(x * tileSize, y * tileSize, 'wall').setOrigin(0);
                    if (wall.body) {
                        wall.body.setSize(tileSize, tileSize);
                        wall.body.setOffset(16, 16);
                    }
                    wall.setName(`wall-${x}-${y}`);
                }
                else {
                    const floor = this.add.image(x * tileSize, y * tileSize, 'floor').setOrigin(0);
                    floor.setName(`tile-${x}-${y}`);
                }
            }
        }
        this.los = new Mrpas(this.maze[0].length, this.maze.length, (x, y) => {
            return this.maze[y] && this.maze[y][x] === 0;
        });
        for (let y = 0; y < this.maze.length; y++) {
            this.fovTiles[y] = [];
            this.permanentVisibleTiles[y] = [];
            for (let x = 0; x < this.maze[y].length; x++) {
                this.fovTiles[y][x] = false;
                this.permanentVisibleTiles[y][x] = false;
            }
        }
        let playerX, playerY;
        do {
            playerX = Phaser.Math.Between(1, this.maze[0].length - 2) * TILE_SIZE + TILE_SIZE / 2;
            playerY = Phaser.Math.Between(1, this.maze.length - 2) * TILE_SIZE + TILE_SIZE / 2;
        } while (this.maze[Math.floor(playerY / TILE_SIZE)][Math.floor(playerX / TILE_SIZE)] !== 0);
        this.player.setPosition(playerX, playerY);
        this.physics.add.existing(this.player);
        this.player.setCollideWorldBounds(true);
        let minotaurX, minotaurY;
        do {
            minotaurX = Phaser.Math.Between(1, this.maze[0].length - 2) * tileSize + tileSize / 2;
            minotaurY = Phaser.Math.Between(1, this.maze.length - 2) * tileSize + tileSize / 2;
        } while (this.maze[Math.floor(minotaurY / tileSize)][Math.floor(minotaurX / tileSize)] !== 0);
        this.minotaur = new Minotaur(this, minotaurX, minotaurY, 'minotaur', this.player, this.sounds);
        this.physics.add.collider(this.player, this.walls);
        this.physics.add.collider(this.minotaur, this.walls);
        this.physics.add.overlap(this.player, this.minotaur, () => {
            if (this.player.hasItem('sword')) {
                this.sounds.playSwordSound();
                this.minotaur.destroy();
                this.sounds.stopChaseLoopSound();
                this.player.removeItem('sword');
            }
            else if (this.player.hasItem('shield')) {
                if (!this.player.isCurrentlyInvincible()) {
                    this.player.setInvincible(8000);
                    this.player.removeItem('shield');
                    this.sounds.playShieldSound();
                }
            }
            else {
                if (!this.player.isCurrentlyInvincible()) {
                    if (this.inventoryVisible) {
                        this.scene.stop('InventoryScene');
                        this.inventoryVisible = false; // Update the state to reflect that the inventory is closed
                    }
                    this.resetTrails();
                    this.scene.start('GameOverScene');
                    this.sounds.stopAllSounds();
                    // Stop JoystickScene only if mobile controls are not enabled
                    const isMobileControlsEnabled = this.registry.get('isMobileControlsEnabled') || false;
                    if (!isMobileControlsEnabled) {
                        this.scene.stop('JoystickScene');
                    }
                }
            }
        }, undefined, this);
        const deadEnds = findDeadEnds(this.maze);
        this.entranceExit = new EntranceExit(this, this.player, this.occupiedPositions, this.sounds);
        this.entranceExit.addEntranceExit(deadEnds);
        this.yarn = new Yarn(this, this.player, this.occupiedPositions, this.sounds);
        this.yarn.addYarn(deadEnds);
        const doorPosition = this.entranceExit.getDoorPosition();
        if (doorPosition) {
            this.yarn.setDoorPosition(doorPosition);
            console.log('Set door position:', doorPosition);
        }
        else {
            console.error('Failed to set door position.');
        }
        this.portals = new Portals(this, this.player, this.occupiedPositions, this.sounds);
        this.portals.addPortals(deadEnds);
        this.items = new Items(this, this.player, this.occupiedPositions, this.sounds);
        this.items.addItems(deadEnds);
        MagicScroll.addMagicScroll(this, this.player, this.occupiedPositions, this.sounds, this.fovTiles);
        this.magicScroll = this.children.getByName('magicscroll');
        MinimapItem.addMinimapItem(this, this.player, this.occupiedPositions, this.sounds, this.fovTiles);
        Torch.addTorch(this, this.player, this.occupiedPositions, this.fovTiles, this.sounds);
        const notesKeys = [
            'note1',
            'note2',
            'note3',
            'note4',
            'note5',
            'note6',
            'note7',
            'note8',
            'note9',
            'note10',
        ];
        const noteForCurrentLevel = notesKeys[this.level - 1]; // Array is 0-based, levels are 1-based
        this.notes = new Notes(this, this.player, this.occupiedPositions, noteForCurrentLevel, this.fovTiles, this.sounds);
        const camera = this.cameras.main;
        camera.startFollow(this.player, true, 0.1, 0.1);
        const zoomLevel = this.registry.get('zoomLevel') || 3;
        camera.setZoom(zoomLevel);
        // Add the fade-in effect for the camera
        camera.fadeIn(2000);
        // Play Game Over Narration with a 2-second delay
        this.time.delayedCall(2000, () => {
            this.sounds.playRandomMainSound();
        });
        this.minimap = this.scene.get('MinimapScene');
        this.trailGraphics = this.add.graphics();
        this.computeFOV();
        if (this.input.keyboard) {
            this.input.keyboard.on('keydown-I', this.toggleInventory, this);
            this.input.keyboard.on('keydown-M', this.toggleMinimap, this);
            this.input.keyboard.on('keydown-Y', this.toggleYarn, this);
            this.input.keyboard.on('keydown-T', this.useTorch, this);
            this.input.keyboard.on('keydown-R', this.createYarnPath, this);
            this.input.keyboard.on('keydown-P', this.activatePortalPotion, this);
            this.input.keyboard.on('keydown-B', this.openNoteReadingScene, this); // Bind 'B' key for opening NoteReadingScene
        }
        this.input.on('pointerdown', this.handleTouchInput, this); // Set up touch input handling
        // Initialize debug graphics (optional)
        // this.debugGraphics = this.add.graphics();
        this.events.on('toggleYarn', this.toggleYarn, this);
        this.events.on('openInventory', this.toggleInventory, this);
        this.events.on('openMinimap', this.toggleMinimap, this);
        this.events.on('toggleTorch', this.useTorch, this);
        // Check if mobile controls should be enabled
        const isMobileControlsEnabled = this.registry.get('isMobileControlsEnabled') || false;
        if (isMobileControlsEnabled) {
            this.scene.launch('JoystickScene', { player: this.player });
            this.scene.bringToTop('JoystickScene');
        }
    }
    clearPath() {
        if (this.path) {
            this.path = [];
        }
    }
    update() {
        this.player.update();
        if (this.path && this.path.length > 0) {
            this.updatePlayerMovementAlongPath();
        }
        if (this.minotaur.active) {
            this.minotaur.update();
        }
        this.computeFOV();
        if (this.player.body) {
            const velocity = this.player.body.velocity;
            const isCurrentlyMoving = velocity.x !== 0 || velocity.y !== 0;
            this.sounds.playFootstepSound(isCurrentlyMoving);
        }
        const distance = Phaser.Math.Distance.Between(this.player.x, this.player.y, this.minotaur.x, this.minotaur.y);
        if (distance < TILE_SIZE * 4 && distance > TILE_SIZE && !this.sounds.isGrowlPlaying) {
            this.sounds.playGrowlSound();
        }
        this.portals.update();
        this.portals.updateVisibility(this.fovTiles);
        this.entranceExit.updateVisibility(this.fovTiles);
        this.yarn.updateVisibility(this.fovTiles);
        this.items.updateVisibility(this.fovTiles);
        this.children.each((child) => {
            if (child instanceof Torch) {
                child.updateVisibility(this.fovTiles);
            }
        });
        if (this.magicScroll) {
            this.magicScroll.updateVisibility(this.fovTiles);
        }
        this.updateTrail();
        if (this.yarnActive && this.player.hasItem('yarn')) {
            this.drawTrail();
        }
        else {
            this.trailGraphics.clear();
        }
        this.updatePlayerMovementAlongPath(); // Update player movement along path
    }
    handleTouchInput(pointer) {
        // Convert the pointer position to grid coordinates
        const gridX = Math.floor(pointer.worldX / TILE_SIZE);
        const gridY = Math.floor(pointer.worldY / TILE_SIZE);
        // Check if the target tile is within bounds
        if (this.grid[gridY] && this.grid[gridY][gridX] !== undefined) {
            // Check if the tile is currently visible
            if (this.fovTiles[gridY][gridX] || this.permanentVisibleTiles[gridY][gridX]) {
                // Proceed with pathfinding
                const finder = new Pathfinding.AStarFinder();
                const gridClone = new Pathfinding.Grid(this.grid);
                const path = finder.findPath(Math.floor(this.player.x / TILE_SIZE), Math.floor(this.player.y / TILE_SIZE), gridX, gridY, gridClone);
                // If a path is found, convert it to world coordinates
                if (path.length > 0) {
                    this.path = path.map(([x, y]) => new Phaser.Math.Vector2(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2));
                }
            }
            else {
                // The tile is not visible; ignore the touch or provide feedback
                console.warn('Cannot move to unseen tile.');
            }
        }
        else {
            console.warn('Target position is out of bounds or not walkable.');
        }
    }
    updatePlayerMovementAlongPath() {
        if (this.path && this.path.length > 0) {
            const nextPoint = this.path[0];
            const distance = Phaser.Math.Distance.Between(this.player.x, this.player.y, nextPoint.x, nextPoint.y);
            if (distance < TILE_SIZE / 4) {
                this.path.shift();
                if (this.path.length === 0) {
                    // Stop the player when the path is completed
                    if (this.player.body) {
                        const body = this.player.body;
                        body.setVelocity(0);
                    }
                }
            }
            else {
                this.physics.moveToObject(this.player, nextPoint, 160);
            }
        }
        // Do not set velocity to zero here
    }
    createGrid() {
        // Deep copy the maze to avoid modifying the original maze
        this.grid = this.maze.map((row) => row.slice());
    }
    openNoteReadingScene() {
        const collectedNotes = this.player.getCollectedNotes();
        console.log('Attempting to open NoteReadingScene with collected notes:', collectedNotes);
        if (collectedNotes.length > 0) {
            this.scene.pause('MainScene');
            this.scene.launch('NoteReadingScene', { notes: collectedNotes });
        }
    }
    computeFOV() {
        if (!this.los || !this.maze || !this.player) {
            return;
        }
        if (!this.isFullVisibility) {
            const visibleTiles = new Set();
            const px = Math.floor(this.player.x / TILE_SIZE);
            const py = Math.floor(this.player.y / TILE_SIZE);
            this.los.compute(px, py, 0 + this.player.getFovRadius(), (x, y) => this.maze[y] && this.maze[y][x] === 0, (x, y) => {
                const tile = this.children.getByName(`tile-${x}-${y}`);
                const wall = this.children.getByName(`wall-${x}-${y}`);
                visibleTiles.add(`${x},${y}`);
                if (tile && tile.alpha < 1) {
                    tile.setAlpha(1);
                }
                if (wall && wall.alpha < 1) {
                    wall.setAlpha(1);
                }
                this.fovTiles[y][x] = true;
                if (this.player.hasMagicScroll) {
                    this.permanentVisibleTiles[y][x] = true;
                }
            });
            for (let y = Math.max(0, py - 10); y < Math.min(this.maze.length, py + 10); y++) {
                for (let x = Math.max(0, px - 10); x < Math.min(this.maze[0].length, px + 10); x++) {
                    if (!visibleTiles.has(`${x},${y}`)) {
                        const tile = this.children.getByName(`tile-${x}-${y}`);
                        const wall = this.children.getByName(`wall-${x}-${y}`);
                        if (tile && !this.permanentVisibleTiles[y][x]) {
                            tile.setAlpha(0);
                        }
                        if (wall && !this.permanentVisibleTiles[y][x]) {
                            wall.setAlpha(0);
                        }
                        this.fovTiles[y][x] = false;
                    }
                }
            }
            if (this.minotaur) {
                const minotaurTileX = Math.floor(this.minotaur.x / TILE_SIZE);
                const minotaurTileY = Math.floor(this.minotaur.y / TILE_SIZE);
                if (this.fovTiles[minotaurTileY][minotaurTileX]) {
                    this.minotaur.setAlpha(1);
                }
                else {
                    this.minotaur.setAlpha(0);
                }
            }
        }
        else {
            this.children.each((child) => {
                if (child instanceof Phaser.GameObjects.Image ||
                    child instanceof Phaser.Physics.Arcade.Sprite) {
                    child.setAlpha(1);
                }
            });
            if (this.minotaur) {
                this.minotaur.setAlpha(1);
            }
        }
    }
    levelComplete() {
        const score = this.player.getScore();
        const currentTotalScore = this.registry.get('totalScore') || 0;
        const newTotalScore = currentTotalScore + score;
        // Update player data and total score in the registry
        this.registry.set('playerData', {
            gold: this.player.getGold(),
            inventory: this.player.getInventory(),
            collectedNotes: this.registry.get('collectedNotes') || [], // Ensure collected notes are carried over
        });
        this.registry.set('totalScore', newTotalScore);
        this.sounds.stopAllSounds();
        this.resetTrails();
        // Stop JoystickScene only if mobile controls are not enabled
        const isMobileControlsEnabled = this.registry.get('isMobileControlsEnabled') || false;
        if (!isMobileControlsEnabled) {
            this.scene.stop('JoystickScene');
        }
        // Reset FOV at the end of the level
        this.player.resetFovRadius();
        // Move to the LevelCompleteScene and pass the necessary data
        this.scene.start('LevelCompleteScene', {
            level: this.level,
            score: score,
            totalScore: newTotalScore,
        });
    }
    useTorch() {
        if (this.player.hasItem('torch')) {
            this.player.useItem('torch');
            console.log('Player used a torch');
            // Increase FOV radius
            this.player.increaseFovRadius();
            this.computeFOV(); // Recompute FOV after activating torch
            // Set up the torch expiration timer
            const newTorchTimer = this.time.delayedCall(TORCH_TIME, this.expireTorch, [], this);
            this.torchTimers.push(newTorchTimer);
        }
    }
    expireTorch() {
        console.log('Torch expired');
        // Place the torch_0 image at the player's current location
        const playerX = this.player.x;
        const playerY = this.player.y;
        const expiredTorch = this.add
            .image(playerX, playerY, 'torch_0')
            .setOrigin(0.5)
            .setDepth(0);
        // Create the "Poof" text above the player
        const poofText = this.add
            .text(playerX, playerY - 20, 'Poof!', {
            fontFamily: 'Comic Sans MS', // or any other comic-style font you have
            fontSize: '12px',
            color: '#ffffff',
            backgroundColor: '#000000',
        })
            .setOrigin(0.5);
        // Animate the text to fade out and then destroy it
        this.tweens.add({
            targets: poofText,
            alpha: 0,
            duration: 2000, // Fade out over 2 seconds
            ease: 'Power2',
            onComplete: () => {
                poofText.destroy();
            },
        });
        // Decrease FOV radius
        this.player.decreaseFovRadius();
        this.computeFOV();
        // Remove the expired torch timer from the array
        this.torchTimers.shift(); // Remove the first timer as it just expired
        // Destroy the torch after 3 seconds
        this.time.delayedCall(3000, () => {
            console.log('Expired torch destroyed');
            expiredTorch.destroy();
        });
    }
    toggleMinimap() {
        if (!this.player.hasItem('map'))
            return;
        if (this.minimapVisible) {
            this.scene.stop('MinimapScene');
        }
        else {
            this.scene.launch('MinimapScene');
        }
        this.minimapVisible = !this.minimapVisible;
    }
    toggleInventory() {
        if (this.inventoryVisible) {
            this.scene.stop('InventoryScene');
        }
        else {
            this.scene.launch('InventoryScene', {
                player: this.player,
                totalScore: this.registry.get('totalScore') || 0,
                levelScore: this.player.getScore(),
            });
        }
        this.inventoryVisible = !this.inventoryVisible;
    }
    toggleYarn() {
        if (this.player.hasItem('yarn')) {
            this.yarnActive = !this.yarnActive;
        }
    }
    createYarnPath() {
        if (this.player.hasItem('yarn')) {
            this.yarn.createPathToAdjacentTile();
        }
    }
    updateTrail() {
        const timestamp = this.time.now;
        const position = { x: this.player.x, y: this.player.y, timestamp: timestamp };
        this.trail.push(position);
        this.trail = this.trail.filter((pos) => timestamp - pos.timestamp <= this.trailLifetime);
        this.fullTrail.push({ x: this.player.x, y: this.player.y });
    }
    drawTrail() {
        this.trailGraphics.clear();
        this.trailGraphics.lineStyle(2, 0xffd700, 1);
        for (let i = 1; i < this.fullTrail.length; i++) {
            const pos1 = this.fullTrail[i - 1];
            const pos2 = this.fullTrail[i];
            const tileX1 = Math.floor(pos1.x / TILE_SIZE);
            const tileY1 = Math.floor(pos1.y / TILE_SIZE);
            const tileX2 = Math.floor(pos2.x / TILE_SIZE);
            const tileY2 = Math.floor(pos2.y / TILE_SIZE);
            if (this.fovTiles[tileY1][tileX1] && this.fovTiles[tileY2][tileX2]) {
                this.trailGraphics.moveTo(pos1.x, pos1.y);
                this.trailGraphics.lineTo(pos2.x, pos2.y);
            }
        }
        this.trailGraphics.strokePath();
    }
    activatePortalPotion() {
        if (this.player.hasItem('portalPotion')) {
            this.player.usePortalPotion();
            this.sounds.playPortalTransferSound();
        }
        else {
            console.log('No portal potion available.');
        }
    }
    resetTrails() {
        this.trail = [];
        this.fullTrail = [];
        this.trailGraphics.clear();
    }
    setFullVisibility(isFullVisibility) {
        this.isFullVisibility = isFullVisibility;
        this.computeFOV();
    }
    getFOVTiles() {
        return this.fovTiles;
    }
    getPermanentVisibleTiles() {
        return this.permanentVisibleTiles;
    }
    getPlayer() {
        return this.player;
    }
    getExitPosition() {
        return this.entranceExit.getExitPosition();
    }
}
