import Phaser from 'phaser';
export default class MinimapScene extends Phaser.Scene {
    background;
    minimapContainer;
    minimap;
    label;
    zoomLevel = 1; // Initial zoom level
    constructor() {
        super({ key: 'MinimapScene' });
    }
    create() {
        const { width, height } = this.scale;
        // Fade out the background
        this.background = this.add.rectangle(0, 0, width, height, 0x000000, 0.5).setOrigin(0);
        // Create the minimap container
        this.minimapContainer = this.add.container(width / 2, height / 2);
        // Add the minimap graphics
        this.minimap = this.add.graphics();
        this.minimapContainer.add(this.minimap);
        // Add a label
        this.label = this.add.text(0, -height / 2 + 40, 'Minimap', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5);
        this.minimapContainer.add(this.label);
        // Draw the minimap border
        this.minimap.lineStyle(2, 0xffffff, 1);
        this.minimap.strokeRect(-width / 4, -height / 4, width / 2, height / 2);
        // Create the minimap content
        this.updateMinimap();
        // Add zoom buttons
        const zoomInButton = this.add.text(0, height / 2 - 80, '+', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();
        this.minimapContainer.add(zoomInButton);
        const zoomOutButton = this.add.text(0, height / 2 - 40, '-', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();
        this.minimapContainer.add(zoomOutButton);
        zoomInButton.on('pointerdown', () => {
            this.zoomLevel = Math.min(4, this.zoomLevel + 0.1); // Max zoom level of 4
            this.updateMinimap();
        });
        zoomOutButton.on('pointerdown', () => {
            this.zoomLevel = Math.max(0.5, this.zoomLevel - 0.1); // Min zoom level of 0.5
            this.updateMinimap();
        });
        // Add a close button
        const closeButton = this.add.text(0, height / 2 - 120, 'Close', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();
        this.minimapContainer.add(closeButton);
        closeButton.on('pointerdown', () => {
            this.scene.stop('MinimapScene');
        });
        // Listen to scene wake event to update minimap when the scene is re-opened
        this.events.on('wake', this.updateMinimap, this);
    }
    updateMinimap() {
        const mainScene = this.scene.get('MainScene');
        const maze = mainScene.registry.get('maze');
        const exitPosition = mainScene.getExitPosition(); // Get the exit position
        if (!maze)
            return;
        const mazeWidth = maze[0].length;
        const mazeHeight = maze.length;
        const containerWidth = this.scale.width / 2;
        const containerHeight = this.scale.height / 2;
        // Scale the minimap to fit within the container, adjusted by zoom level
        const scaleX = (containerWidth / mazeWidth) * this.zoomLevel;
        const scaleY = (containerHeight / mazeHeight) * this.zoomLevel;
        const scale = Math.min(scaleX, scaleY);
        const tileSize = Math.max(1, Math.floor(scale)); // Ensure tile size is at least 1
        this.minimap.clear();
        this.minimap.fillStyle(0xffffff);
        for (let y = 0; y < maze.length; y++) {
            for (let x = 0; x < maze[y].length; x++) {
                if (maze[y][x] === 1) {
                    this.minimap.fillStyle(0x808080); // Gray for walls
                }
                else {
                    this.minimap.fillStyle(0xffffff); // White for paths
                }
                this.minimap.fillRect(x * tileSize - mazeWidth * tileSize / 2, y * tileSize - mazeHeight * tileSize / 2, tileSize, tileSize);
            }
        }
        // Draw the exit location
        if (exitPosition) {
            this.minimap.fillStyle(0x00ff00); // Green for exit
            this.minimap.fillRect(exitPosition.x * tileSize - mazeWidth * tileSize / 2, exitPosition.y * tileSize - mazeHeight * tileSize / 2, tileSize, tileSize);
        }
    }
}
