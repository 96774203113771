import Phaser from 'phaser';
import Player from '../entities/Player';
import Sounds from '../utils/Sounds'; // Import the Sounds class
import { TILE_SIZE } from '../utils/constants';
import Pathfinding from 'pathfinding'; // Ensure pathfinding is imported
export default class LevelPickingScene extends Phaser.Scene {
    player;
    doors;
    camera;
    cursors;
    currentLevel;
    storeZone;
    storeOpen = false;
    inventoryVisible = false;
    sounds; // Declare a variable for the Sounds class
    targetPosition; // Target position for touch controls
    grid; // Grid for pathfinding
    path; // Path to follow
    walls;
    constructor() {
        super('LevelPickingScene');
    }
    init(data) {
        this.currentLevel = data.level || 1; // Default to level 1 if not provided
        this.registry.set('totalScore', data.totalScore || 0);
        // Retrieve or reset player data
        let playerData = data.playerData || this.registry.get('playerData');
        if (!playerData || Object.keys(playerData.inventory).length === 0) {
            playerData = { gold: 0, inventory: {}, collectedNotes: [] }; // Reset to default if missing or empty
        }
        this.registry.set('playerData', playerData);
        console.log('Player Data on Init:', playerData);
    }
    create() {
        this.sounds = new Sounds(this); // Initialize the Sounds class
        this.resetScenes();
        const roomWidth = 10;
        const roomHeight = 8;
        this.doors = this.physics.add.group();
        this.camera = this.cameras.main;
        this.camera.setZoom(3);
        // Add fade-in effect
        this.camera.fadeIn(2000);
        this.createRoom(roomWidth, roomHeight);
        this.createPlayer();
        this.createDoors();
        this.createInteriorWalls(roomWidth, roomHeight);
        // Create the store zone in the center of the room
        this.createStoreZone(roomWidth, roomHeight);
        this.setupPlayerMovement();
        this.camera.startFollow(this.player);
        this.time.delayedCall(2000, () => {
            this.sounds.playRandomLevelSound(); // Play a random level sound
        });
        if (this.input.keyboard) {
            this.cursors = this.input.keyboard.createCursorKeys();
        }
        if (this.input.keyboard) {
            this.input.keyboard.on('keydown-I', this.toggleInventory, this);
            this.input.keyboard.on('keydown-B', this.openNoteReadingScene, this); // Bind 'B' key for opening NoteReadingScene
        }
        // Check if mobile controls should be enabled
        const isMobileControlsEnabled = this.registry.get('isMobileControlsEnabled') || false;
        if (isMobileControlsEnabled) {
            this.scene.launch('JoystickScene', { player: this.player });
            this.scene.bringToTop('JoystickScene');
        }
        // Setup touch controls
        this.input.on('pointerdown', this.handleTouchInput, this);
        // Create the grid for pathfinding
        this.createGrid(roomWidth, roomHeight);
    }
    update() {
        this.handlePlayerMovement();
        this.checkStoreInteraction();
        this.updatePlayerMovementAlongPath();
    }
    handleTouchInput(pointer) {
        // Convert the pointer position to grid coordinates
        const gridX = Math.floor(pointer.worldX / TILE_SIZE);
        const gridY = Math.floor(pointer.worldY / TILE_SIZE);
        // Find the path to the target position using A* pathfinding
        const pathfinder = new Pathfinding.AStarFinder();
        const gridClone = new Pathfinding.Grid(this.grid);
        const path = pathfinder.findPath(Math.floor(this.player.x / TILE_SIZE), Math.floor(this.player.y / TILE_SIZE), gridX, gridY, gridClone);
        // Convert the path to world coordinates
        this.path = path.map(([x, y]) => new Phaser.Math.Vector2(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2));
    }
    updatePlayerMovementAlongPath() {
        if (this.path && this.path.length > 0) {
            const nextPoint = this.path[0];
            const body = this.player.body;
            // Move the player towards the next point in the path
            const distance = Phaser.Math.Distance.Between(this.player.x, this.player.y, nextPoint.x, nextPoint.y);
            if (distance < TILE_SIZE / 4) {
                // Remove the point from the path if the player is close enough
                this.path.shift();
            }
            else {
                this.physics.moveToObject(this.player, nextPoint, 160);
            }
        }
    }
    createGrid(roomWidth, roomHeight) {
        this.grid = [];
        for (let y = 0; y < roomHeight; y++) {
            const row = [];
            for (let x = 0; x < roomWidth; x++) {
                // Mark tiles as walkable (0) or unwalkable (1)
                const hasWall = this.walls.getChildren().some(wall => wall.x === x * TILE_SIZE + TILE_SIZE / 2 && wall.y === y * TILE_SIZE + TILE_SIZE / 2);
                row.push(hasWall ? 1 : 0);
            }
            this.grid.push(row);
        }
    }
    resetScenes() {
        this.scene.stop('MainScene');
        this.scene.stop('StoreScene');
        this.scene.stop('MinimapScene');
        // Stop JoystickScene only if mobile controls are not enabled
        const isMobileControlsEnabled = this.registry.get('isMobileControlsEnabled') || false;
        if (!isMobileControlsEnabled) {
            this.scene.stop('JoystickScene');
        }
    }
    createRoom(roomWidth, roomHeight) {
        this.walls = this.physics.add.staticGroup();
        for (let x = 0; x < roomWidth; x++) {
            for (let y = 0; y < roomHeight; y++) {
                const floor = this.add.image(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2, 'floor').setOrigin(0.5).setDepth(0);
            }
        }
        this.createWalls(roomWidth, roomHeight);
    }
    createPlayer() {
        const playerData = this.registry.get('playerData');
        console.log('Creating Player with Data:', playerData);
        // Pass the sounds object before the playerData
        this.player = new Player(this, TILE_SIZE * 1.5, TILE_SIZE * 1.5, 'player', this.sounds, playerData);
        this.player.setLevel(this.currentLevel);
        this.physics.add.existing(this.player);
        // Ensure the player collides with the walls
        this.physics.add.collider(this.player, this.walls);
    }
    createStoreZone(roomWidth, roomHeight) {
        const storeX = roomWidth * TILE_SIZE / 2 + TILE_SIZE / 2;
        const storeY = roomHeight * TILE_SIZE / 2 + TILE_SIZE / 2;
        this.storeZone = this.physics.add.sprite(storeX, storeY, 'sealed_door').setOrigin(0.5);
        this.storeZone.setImmovable(true);
        this.physics.add.overlap(this.player, this.storeZone, this.openStore, undefined, this);
    }
    openStore() {
        if (!this.storeOpen) {
            this.storeOpen = true;
            this.scene.launch('StoreScene', { player: this.player, sounds: this.sounds });
            this.scene.bringToTop('StoreScene'); // Ensure the StoreScene is brought to the top
        }
    }
    closeStore() {
        if (this.storeOpen) {
            this.storeOpen = false;
            this.scene.stop('StoreScene');
        }
    }
    checkStoreInteraction() {
        const distance = Phaser.Math.Distance.Between(this.player.x, this.player.y, this.storeZone.x, this.storeZone.y);
        if (distance > TILE_SIZE * 1.5) {
            this.closeStore();
        }
    }
    createDoors() {
        const doorPositions = [
            { x: 2, y: 0 }, { x: 4, y: 0 }, { x: 6, y: 0 }, { x: 8, y: 0 },
            { x: 0, y: 2 }, { x: 0, y: 4 }, { x: 0, y: 6 },
            { x: 9, y: 2 }, { x: 9, y: 4 }, { x: 9, y: 6 }
        ];
        doorPositions.forEach((pos, index) => {
            const doorLevel = index + 1;
            let doorTexture = 'runed_door';
            if (doorLevel < this.currentLevel) {
                doorTexture = 'open_door';
            }
            else if (doorLevel === this.currentLevel) {
                doorTexture = 'runed_door';
            }
            const door = this.doors.create(pos.x * TILE_SIZE + TILE_SIZE / 2, pos.y * TILE_SIZE + TILE_SIZE / 2, doorTexture);
            door.setOrigin(0.5, 0.5);
            door.setData('level', doorLevel);
            door.setDepth(0);
            this.add.text(pos.x * TILE_SIZE + TILE_SIZE / 2, pos.y * TILE_SIZE - TILE_SIZE / 2, `${doorLevel}`, { fontSize: '16px', color: '#fff' }).setOrigin(0.5).setDepth(0);
        });
        this.physics.add.overlap(this.player, this.doors, (player, door) => this.enterLevel(player, door), undefined, this);
    }
    enterLevel(player, door) {
        const doorLevel = door.getData('level');
        const playerLevel = player.getLevel();
        if (doorLevel < playerLevel) {
            console.log(`Level ${doorLevel} is already completed.`);
        }
        else if (doorLevel === playerLevel) {
            // Use a workaround to access the private sounds array
            const soundManager = this.sound; // Use 'any' type to bypass TypeScript checks
            // Access the 'sounds' array
            const soundsArray = soundManager['sounds'];
            soundsArray.forEach((sound) => {
                if (sound.isPlaying) {
                    this.tweens.add({
                        targets: sound,
                        volume: 0,
                        duration: 1000, // Adjust the duration as needed
                        onComplete: () => sound.stop(),
                    });
                }
            });
            // Set a delay to ensure sounds fade out before transitioning
            this.time.delayedCall(1000, () => {
                const playerData = this.registry.get('playerData');
                this.registry.set('currentLevel', doorLevel);
                this.scene.start('MainScene', {
                    level: this.currentLevel,
                    totalScore: this.registry.get('totalScore'),
                    playerData: this.registry.get('playerData'),
                });
                console.log('Player Data:', this.registry.get('playerData'));
                this.scene.stop('LevelPickingScene');
                this.scene.stop('JoystickScene');
                this.scene.stop('MinimapScene');
                this.sounds.stopAllSounds();
                // Stop any other scenes that might be lingering
            });
        }
        else {
            console.log(`Door ${doorLevel} is locked. Complete the previous level first.`);
        }
    }
    setupPlayerMovement() {
        this.player.setCollideWorldBounds(true);
    }
    createWalls(roomWidth, roomHeight) {
        // List of door positions to exclude from wall creation
        const doorPositions = [
            { x: 2, y: 0 }, { x: 4, y: 0 }, { x: 6, y: 0 }, { x: 8, y: 0 },
            { x: 0, y: 2 }, { x: 0, y: 4 }, { x: 0, y: 6 },
            { x: 9, y: 2 }, { x: 9, y: 4 }, { x: 9, y: 6 }
        ];
        // Function to check if a position should have a wall or not
        const shouldHaveWall = (x, y) => {
            return !doorPositions.some(pos => pos.x === x && pos.y === y);
        };
        for (let x = 0; x < roomWidth; x++) {
            for (let y = 0; y < roomHeight; y++) {
                if (y === 0 || y === roomHeight - 1 || x === 0 || x === roomWidth - 1) {
                    if (shouldHaveWall(x, y)) {
                        const wall = this.walls.create(x * TILE_SIZE + TILE_SIZE / 2, y * TILE_SIZE + TILE_SIZE / 2, 'wall').setOrigin(0.5).setDepth(0);
                    }
                }
            }
        }
    }
    createInteriorWalls(roomWidth, roomHeight) {
        // Adding some interior walls for demonstration
        const interiorWallPositions = [
            { x: 4, y: 2 }, { x: 4, y: 3 }, { x: 4, y: 4 },
            { x: 7, y: 2 }, { x: 7, y: 3 }, { x: 7, y: 4 }
        ];
        interiorWallPositions.forEach(pos => {
            const wall = this.walls.create(pos.x * TILE_SIZE + TILE_SIZE / 2, pos.y * TILE_SIZE + TILE_SIZE / 2, 'wall').setOrigin(0.5).setDepth(0);
        });
    }
    handlePlayerMovement() {
        const speed = 160;
        const body = this.player.body;
        body.setVelocity(0);
        if (this.cursors.left?.isDown || this.player.joystick?.left) {
            body.setVelocityX(-speed);
        }
        else if (this.cursors.right?.isDown || this.player.joystick?.right) {
            body.setVelocityX(speed);
        }
        if (this.cursors.up?.isDown || this.player.joystick?.up) {
            body.setVelocityY(-speed);
        }
        else if (this.cursors.down?.isDown || this.player.joystick?.down) {
            body.setVelocityY(speed);
        }
    }
    toggleInventory() {
        if (this.inventoryVisible) {
            this.scene.stop('InventoryScene');
        }
        else {
            this.scene.launch('InventoryScene', {
                player: this.player,
                totalScore: this.registry.get('totalScore') || 0,
                levelScore: this.player.getScore()
            });
            this.scene.bringToTop('InventoryScene');
        }
        this.inventoryVisible = !this.inventoryVisible;
    }
    openNoteReadingScene() {
        const collectedNotes = this.player.getCollectedNotes();
        if (collectedNotes && collectedNotes.length > 0) {
            this.scene.launch('NoteReadingScene', { notes: collectedNotes });
            this.scene.pause('LevelPickingScene'); // Pause this scene while reading notes
            this.scene.bringToTop('NoteReadingScene');
        }
        else {
            console.warn('No notes collected or available to display.');
        }
    }
}
