export default class MazeGenerator {
    width;
    height;
    maze;
    constructor(width, height) {
        this.width = width;
        this.height = height;
        this.maze = Array.from({ length: height }, () => Array(width).fill(1));
    }
    generate() {
        const stack = [];
        let currentCell = { x: 1, y: 1 };
        this.maze[currentCell.y][currentCell.x] = 0;
        stack.push(currentCell);
        const directions = [
            { x: 0, y: -2 }, // Up
            { x: 2, y: 0 }, // Right
            { x: 0, y: 2 }, // Down
            { x: -2, y: 0 } // Left
        ];
        while (stack.length > 0) {
            const neighbors = directions
                .map(dir => ({
                x: currentCell.x + dir.x,
                y: currentCell.y + dir.y,
                dir
            }))
                .filter(neighbor => neighbor.x > 0 && neighbor.x < this.width - 1 &&
                neighbor.y > 0 && neighbor.y < this.height - 1 &&
                this.maze[neighbor.y][neighbor.x] === 1);
            if (neighbors.length > 0) {
                const next = neighbors[Math.floor(Math.random() * neighbors.length)];
                this.maze[next.y][next.x] = 0;
                this.maze[currentCell.y + next.dir.y / 2][currentCell.x + next.dir.x / 2] = 0;
                currentCell = { x: next.x, y: next.y };
                stack.push(currentCell);
            }
            else {
                currentCell = stack.pop();
            }
        }
        return this.maze;
    }
}
