import Phaser from 'phaser';
import { TILE_SIZE } from '../utils/constants';
const TORCH_DURATION = 15000; // Duration of the torch in milliseconds (15 seconds)
export default class Torch extends Phaser.Physics.Arcade.Sprite {
    fovTiles;
    player;
    activeTorches = [];
    permanentlyVisibleTorches = new Set();
    torchScene;
    sounds;
    constructor(scene, x, y, fovTiles, player, sounds) {
        super(scene, x, y, 'torch');
        this.torchScene = scene;
        this.fovTiles = fovTiles;
        this.player = player;
        this.sounds = sounds;
        scene.add.existing(this);
        scene.physics.add.existing(this);
        this.setOrigin(0.5);
        this.setScale(0.5);
        if (this.body) {
            this.body.setSize(TILE_SIZE, TILE_SIZE);
            this.body.setOffset(16, 16);
        }
        this.torchScene.events.on('update', this.update, this);
    }
    static addTorch(scene, player, occupiedPositions, fovTiles, sounds) {
        let torchX, torchY;
        const maze = scene.registry.get('maze');
        for (let i = 0; i < 3; i++) {
            do {
                torchX = Phaser.Math.Between(1, maze[0].length - 2);
                torchY = Phaser.Math.Between(1, maze.length - 2);
            } while (maze[torchY][torchX] !== 0 || occupiedPositions.has(`${torchX},${torchY}`));
            const torch = new Torch(scene, torchX * TILE_SIZE + TILE_SIZE / 2, torchY * TILE_SIZE + TILE_SIZE / 2, fovTiles, player, sounds);
            scene.physics.add.overlap(player, torch, () => {
                player.addItem('torch');
                torch.sounds.playRandomTorchSound(); // Play random torch sound on pickup
                torch.sounds.playYarnPickupSound(); // Play yarn pickup sound on pickup
                torch.destroy();
            });
            occupiedPositions.add(`${torchX},${torchY}`);
        }
    }
    activate() {
        console.log('Torch activated');
        const currentTime = this.scene.time.now;
        this.activeTorches.push({ endTime: currentTime + TORCH_DURATION, x: this.x, y: this.y });
        this.player.increaseFovRadius(); // Increase FOV
        this.updateFov();
        this.sounds.playTorchOnSound(); // Play torch on sound when used
        this.sounds.playTorchUseSound(); // Play torch use sound when used
        console.log(`Torch will expire in ${TORCH_DURATION / 1000} seconds`);
    }
    update() {
        const currentTime = this.torchScene.time.now;
        const originalTorchCount = this.activeTorches.length;
        this.activeTorches = this.activeTorches.filter(torch => torch.endTime > currentTime);
        if (this.activeTorches.length !== originalTorchCount) {
            this.player.decreaseFovRadius();
            this.updateFov();
        }
    }
    updateFov() {
        const currentFovRadius = 1 + this.activeTorches.length;
        this.torchScene.computeFOV(currentFovRadius);
    }
    updateVisibility(fovTiles) {
        const tileX = Math.floor(this.x / TILE_SIZE);
        const tileY = Math.floor(this.y / TILE_SIZE);
        const torchKey = `${tileX},${tileY}`;
        if (this.player.hasMagicScroll && fovTiles[tileY] && fovTiles[tileY][tileX]) {
            this.permanentlyVisibleTorches.add(torchKey);
            this.setAlpha(1);
        }
        else if (fovTiles[tileY] && fovTiles[tileY][tileX]) {
            this.setAlpha(1);
        }
        else if (this.permanentlyVisibleTorches.has(torchKey)) {
            this.setAlpha(1);
        }
        else {
            this.setAlpha(0);
        }
    }
}
