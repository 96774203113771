import Phaser from 'phaser';
export default class BootScene extends Phaser.Scene {
    constructor() {
        super('BootScene');
    }
    preload() {
        // Load images
        this.load.image('player', 'assets/tiles/player.png');
        this.load.image('wall', 'assets/tiles/wall.png');
        this.load.image('floor', 'assets/tiles/floor.png');
        this.load.image('portal', 'assets/tiles/portal.png');
        this.load.image('button', 'assets/tiles/button.png');
        this.load.image('up', 'assets/tiles/up.png');
        this.load.image('down', 'assets/tiles/down.png');
        this.load.image('door', 'assets/tiles/door.png');
        this.load.image('dooropen', 'assets/tiles/dooropen.png');
        this.load.image('key', 'assets/tiles/key.png');
        this.load.image('yarn', 'assets/tiles/yarn.png');
        this.load.image('gold', 'assets/tiles/gold.png');
        this.load.image('chest', 'assets/tiles/chest.png');
        this.load.image('minotaur', 'assets/tiles/minotaur.png');
        this.load.image('sealed_door', 'assets/tiles/sealed_door.png');
        this.load.image('sword', 'assets/tiles/sword.png');
        this.load.image('shield', 'assets/tiles/shield.png');
        this.load.image('torch', 'assets/tiles/torch_1.png');
        this.load.image('titlescreen', 'assets/tiles/titlescreen.jpg');
        this.load.image('chestopen', 'assets/tiles/chestopen.png');
        this.load.image('magicscroll', 'assets/tiles/magicscroll.png');
        this.load.image('minimap', 'assets/tiles/map.png');
        this.load.image('note', 'assets/tiles/note.png');
        this.load.image('portalpotion', 'assets/tiles/portalpotion.png');
        this.load.image('runed_door', 'assets/tiles/runed_door.png');
        this.load.image('open_door', 'assets/tiles/open_door.png');
        this.load.image('torch_0', 'assets/tiles/torch_0.png');
        //Load Fonts
        // Load audio
        this.load.audio('soundtrack', 'assets/sound/soundtrack.mp3');
        this.load.audio('running', 'assets/sound/running.mp3');
        this.load.audio('minotaurcontact', 'assets/sound/minotaurcontact.mp3');
        this.load.audio('growl', 'assets/sound/growl.mp3');
        this.load.audio('portaltransfer', 'assets/sound/portaltransfer.mp3');
        this.load.audio('portalisnear', 'assets/sound/portalisnear.mp3');
        this.load.audio('key', 'assets/sound/key.mp3');
        this.load.audio('gate', 'assets/sound/gate.mp3');
        this.load.audio('chest', 'assets/sound/chest.mp3');
        this.load.audio('coin', 'assets/sound/coin.mp3'); // Load the coin pickup sound
        this.load.audio('yarnpickup', 'assets/sound/yarnpickup.mp3');
        this.load.audio('chaseloop', 'assets/sound/chaseloop.mp3');
        this.load.audio('mainmenu', 'assets/sound/mainmenu.mp3');
        this.load.audio('gameover', 'assets/sound/gameover.mp3');
        this.load.audio('inventoryopen', 'assets/sound/inventoryopen.mp3');
        this.load.audio('inventoryclose', 'assets/sound/inventoryclose.mp3');
        this.load.audio('monsterLOS', 'assets/sound/monsterlos.mp3');
        this.load.audio('story', 'assets/sound/story.mp3');
        this.load.audio('shield', 'assets/sound/shield.mp3');
        this.load.audio('sword', 'assets/sound/sword.mp3');
        this.load.audio('pageturn', 'assets/sound/pageturn.mp3');
        this.load.audio('pageturn2', 'assets/sound/pageturn2.mp3');
        this.load.audio('bookscene', 'assets/sound/bookscene.mp3');
        this.load.audio('levels1', 'assets/player/levels1.mp3');
        this.load.audio('levels2', 'assets/player/levels2.mp3');
        this.load.audio('levels3', 'assets/player/levels3.mp3');
        this.load.audio('levels4', 'assets/player/levels4.mp3');
        this.load.audio('levels5', 'assets/player/levels5.mp3');
        this.load.audio('levels6', 'assets/player/levels6.mp3');
        this.load.audio('main1', 'assets/player/main1.mp3');
        this.load.audio('main2', 'assets/player/main2.mp3');
        this.load.audio('main3', 'assets/player/main3.mp3');
        this.load.audio('main4', 'assets/player/main4.mp3');
        this.load.audio('main5', 'assets/player/main5.mp3');
        this.load.audio('main6', 'assets/player/main6.mp3');
        this.load.audio('main7', 'assets/player/main7.mp3');
        this.load.audio('main8', 'assets/player/main8.mp3');
        this.load.audio('store1', 'assets/player/store1.mp3');
        this.load.audio('store2', 'assets/player/store2.mp3');
        this.load.audio('store3', 'assets/player/store3.mp3');
        this.load.audio('store4', 'assets/player/store4.mp3');
        this.load.audio('key1', 'assets/player/key1.mp3');
        this.load.audio('key2', 'assets/player/key2.mp3');
        this.load.audio('key3', 'assets/player/key3.mp3');
        this.load.audio('gold1', 'assets/player/gold1.mp3');
        this.load.audio('gold2', 'assets/player/gold2.mp3');
        this.load.audio('gold3', 'assets/player/gold3.mp3');
        this.load.audio('potion1', 'assets/player/potion1.mp3');
        this.load.audio('potion2', 'assets/player/potion2.mp3');
        this.load.audio('potion3', 'assets/player/potion3.mp3');
        this.load.audio('potion4', 'assets/player/potion4.mp3');
        this.load.audio('idle1', 'assets/player/idle1.mp3');
        this.load.audio('idle2', 'assets/player/idle2.mp3');
        this.load.audio('idle3', 'assets/player/idle3.mp3');
        this.load.audio('idle4', 'assets/player/idle4.mp3');
        this.load.audio('idle5', 'assets/player/idle5.mp3');
        this.load.audio('idle6', 'assets/player/idle6.mp3');
        this.load.audio('idle7', 'assets/player/idle7.mp3');
        this.load.audio('idle8', 'assets/player/idle8.mp3');
        this.load.audio('idle9', 'assets/player/idle9.mp3');
        this.load.audio('idle10', 'assets/player/idle10.mp3');
        this.load.audio('minotaurisnear', 'assets/player/minotaurisnear.mp3');
        this.load.audio('minotaurisnear2', 'assets/player/minotaurisnear2.mp3');
        this.load.audio('minotaurtrail', 'assets/player/minotaurtrail.mp3');
        this.load.audio('scroll1', 'assets/player/scroll1.mp3');
        this.load.audio('scroll2', 'assets/player/scroll2.mp3');
        this.load.audio('scroll3', 'assets/player/scroll3.mp3');
        this.load.audio('map1', 'assets/player/map1.mp3');
        this.load.audio('map2', 'assets/player/map2.mp3');
        this.load.audio('map3', 'assets/player/map3.mp3');
        this.load.audio('yarn1', 'assets/player/yarn1.mp3');
        this.load.audio('yarn2', 'assets/player/yarn2.mp3');
        this.load.audio('yarn3', 'assets/player/yarn3.mp3');
        this.load.audio('yarn4', 'assets/player/yarn4.mp3');
        this.load.audio('torch1', 'assets/player/torch1.mp3');
        this.load.audio('torch2', 'assets/player/torch2.mp3');
        this.load.audio('torchuse', 'assets/player/torchuse.mp3');
        this.load.audio('torchon', 'assets/sound/torchon.mp3');
        this.load.audio('note1Narration', 'assets/narration/note1.mp3');
        this.load.audio('note2Narration', 'assets/narration/note2.mp3');
        this.load.audio('note3Narration', 'assets/narration/note3.mp3');
        this.load.audio('note4Narration', 'assets/narration/note4.mp3');
        this.load.audio('note5Narration', 'assets/narration/note5.mp3');
        this.load.audio('note6Narration', 'assets/narration/note6.mp3');
        this.load.audio('note7Narration', 'assets/narration/note7.mp3');
        this.load.audio('note8Narration', 'assets/narration/note8.mp3');
        this.load.audio('note9Narration', 'assets/narration/note9.mp3');
        this.load.audio('note10Narration', 'assets/narration/note10.mp3');
        this.load.audio('gameovernarration', 'assets/narration/gameovernarration.mp3');
        this.load.audio('gamecompletenarration', 'assets/narration/gamecompletenarration.mp3');
        this.load.audio('levelcompletenarration', 'assets/narration/levelcompletenarative.mp3');
    }
    create() {
        // Lock orientation to landscape on mobile devices
        if (this.sys.game.device.os.android || this.sys.game.device.os.iOS) {
            this.scale.lockOrientation('landscape');
        }
        this.scene.start('MainMenuScene');
    }
}
