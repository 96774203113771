import Phaser from 'phaser';
export function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
// Function to identify dead ends in the maze
export function findDeadEnds(maze) {
    const deadEnds = [];
    const directions = [
        { x: 0, y: -1 }, // Up
        { x: 0, y: 1 }, // Down
        { x: -1, y: 0 }, // Left
        { x: 1, y: 0 } // Right
    ];
    for (let y = 1; y < maze.length - 1; y++) {
        for (let x = 1; x < maze[y].length - 1; x++) {
            if (maze[y][x] === 0) {
                let wallsCount = 0;
                directions.forEach(dir => {
                    if (maze[y + dir.y][x + dir.x] === 1) {
                        wallsCount++;
                    }
                });
                if (wallsCount === 3) {
                    deadEnds.push({ x, y });
                }
            }
        }
    }
    return deadEnds;
}
// Function to place portals in different quadrants
export function placePortals(deadEnds, width, height) {
    const quadrants = [
        { x1: 0, y1: 0, x2: width / 2, y2: height / 2 },
        { x1: width / 2, y1: 0, x2: width, y2: height / 2 },
        { x1: 0, y1: height / 2, x2: width / 2, y2: height },
        { x1: width / 2, y1: height / 2, x2: width, y2: height }
    ];
    const portals = [];
    quadrants.forEach((quadrant, index) => {
        const candidates = deadEnds.filter(de => de.x >= quadrant.x1 && de.x < quadrant.x2 && de.y >= quadrant.y1 && de.y < quadrant.y2);
        if (candidates.length > 0) {
            const chosen = Phaser.Math.RND.pick(candidates);
            portals.push(chosen);
            deadEnds = deadEnds.filter(de => de !== chosen);
        }
    });
    if (portals.length < 2) {
        throw new Error('Not enough dead ends to place portals in different quadrants');
    }
    return { portal1: portals[0], portal2: portals[1] };
}
// Function to reset the game
export function resetGame(scene) {
    scene.scene.restart();
}
// Function to handle game over
export function gameOver(scene) {
    // Shake the camera
    scene.cameras.main.shake(500); // Shake the camera for 500 milliseconds
    // Stop the game and display a game over message
    scene.physics.pause();
    scene.add.text(scene.scale.width / 2, scene.scale.height / 2, 'Game Over', { fontSize: '64px', color: '#ff0000' }).setOrigin(0.5);
}
