import Phaser from 'phaser';
import Notes from '../entities/Notes'; // Import the Notes class
const TILE_SIZE = 32; // Make sure this matches the TILE_SIZE from your constants
export default class OptionsScene extends Phaser.Scene {
    isPlaying;
    zoomLevel = 3;
    zoomText;
    tileSizeText; // To display the tile size
    isFullVisibility = false; // Add a state variable for full visibility
    isDrawPaths = false; // Add a state variable for drawn paths
    isMobileControlsEnabled = false; // Add a state variable for mobile controls
    fullVisibilityButton; // Button to toggle full visibility
    drawPathsButton; // Button to toggle drawn paths
    mobileControlsButton; // Button to toggle mobile controls
    constructor() {
        super('OptionsScene');
        this.isPlaying = true;
    }
    create() {
        const { width, height } = this.scale;
        // Calculate tile size
        const numRows = Math.ceil(height / TILE_SIZE);
        const numCols = Math.ceil(width / TILE_SIZE);
        // Add background rectangle
        this.add.rectangle(0, 0, width, height, 0x000000, 0.7).setOrigin(0);
        // Add title
        this.add.text(width * 0.5, height * 0.2, 'Options', {
            fontSize: `${Math.round(width * 0.05)}px`,
            color: '#fff'
        }).setOrigin(0.5);
        // Add Soundtrack Toggle button with checkbox
        this.createCheckboxWithLabel(width * 0.5, height * 0.4, 'Toggle Soundtrack', this.isPlaying, (checked) => {
            this.isPlaying = checked;
            this.toggleSoundtrack();
        });
        // Add Zoom Level text
        this.zoomText = this.add.text(width * 0.5, height * 0.5, `Zoom Level: ${this.zoomLevel}`, {
            fontSize: `${Math.round(width * 0.04)}px`,
            color: '#fff'
        }).setOrigin(0.5);
        // Add Tile Size text
        this.tileSizeText = this.add.text(width * 0.5, height * 0.6, `Tile Size: ${numCols}x${numRows}`, {
            fontSize: `${Math.round(width * 0.04)}px`,
            color: '#fff'
        }).setOrigin(0.5);
        // Add Zoom buttons
        this.createZoomButton(width * 0.33, height * 0.7, '1x', 1);
        this.createZoomButton(width * 0.5, height * 0.7, '2x', 2);
        this.createZoomButton(width * 0.67, height * 0.7, '3x', 3);
        // Add Full Visibility Toggle button with checkbox
        this.fullVisibilityButton = this.createCheckboxWithLabel(width * 0.5, height * 0.8, 'Toggle Full Visibility', this.isFullVisibility, (checked) => {
            this.isFullVisibility = checked;
            this.toggleFullVisibility(this.isFullVisibility);
        });
        // Add Draw Paths Toggle button with checkbox
        this.drawPathsButton = this.createCheckboxWithLabel(width * 0.5, height * 0.85, 'Toggle Draw Paths', this.isDrawPaths, (checked) => {
            this.isDrawPaths = checked;
            this.toggleDrawPaths(this.isDrawPaths);
        });
        // Add "Unlock All Notes" button
        const unlockNotesButton = this.add.text(width * 0.5, height * 0.9, 'Unlock All Notes', {
            fontSize: `${Math.round(width * 0.04)}px`,
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setInteractive();
        unlockNotesButton.on('pointerdown', () => {
            this.unlockAllNotes();
        });
        // Add Mobile Controls Toggle button with checkbox
        this.mobileControlsButton = this.createCheckboxWithLabel(width * 0.5, height * 0.75, 'Toggle Mobile Controls', this.isMobileControlsEnabled, (checked) => {
            this.isMobileControlsEnabled = checked;
            this.toggleMobileControls(this.isMobileControlsEnabled);
        });
        // Add Back button
        const backButton = this.add.text(width * 0.5, height * 0.95, 'Back', {
            fontSize: `${Math.round(width * 0.04)}px`,
            color: '#fff'
        }).setOrigin(0.5).setInteractive();
        backButton.on('pointerdown', () => {
            this.scene.start('MainMenuScene');
        });
    }
    toggleSoundtrack() {
        const mainScene = this.scene.get('MainScene');
        if (this.isPlaying) {
            mainScene.sound.resumeAll();
        }
        else {
            mainScene.sound.pauseAll();
        }
    }
    createZoomButton(x, y, label, zoom) {
        const button = this.add.text(x, y, label, {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setInteractive();
        button.on('pointerdown', () => {
            this.zoomLevel = zoom;
            this.zoomText.setText(`Zoom Level: ${this.zoomLevel}`);
            this.registry.set('zoomLevel', this.zoomLevel); // Store zoom level in registry
        });
    }
    toggleFullVisibility(isFullVisibility) {
        const mainScene = this.scene.get('MainScene');
        mainScene.setFullVisibility(isFullVisibility);
    }
    toggleDrawPaths(isDrawPaths) {
        this.registry.set('isDrawPathsEnabled', isDrawPaths);
    }
    toggleMobileControls(isEnabled) {
        const mainScene = this.scene.get('MainScene');
        if (isEnabled) {
            if (!mainScene.scene.isActive('JoystickScene')) {
                mainScene.scene.launch('JoystickScene', { player: mainScene.getPlayer() });
            }
        }
        else {
            if (mainScene.scene.isActive('JoystickScene')) {
                mainScene.scene.stop('JoystickScene');
            }
        }
    }
    createCheckboxWithLabel(x, y, label, initialState, onChange) {
        const checkboxSize = 24;
        const labelText = this.add.text(x, y, label, {
            fontSize: '24px',
            color: '#fff'
        }).setOrigin(0.5);
        const checkbox = this.add.rectangle(labelText.x - labelText.width / 2 - checkboxSize - 10, y, checkboxSize, checkboxSize, initialState ? 0x00ff00 : 0xff0000)
            .setOrigin(0.5).setInteractive();
        const underline = this.add.rectangle(labelText.x, y + 18, labelText.width, 2, initialState ? 0x00ff00 : 0xff0000)
            .setOrigin(0.5);
        checkbox.on('pointerdown', () => {
            initialState = !initialState;
            checkbox.setFillStyle(initialState ? 0x00ff00 : 0xff0000);
            underline.setFillStyle(initialState ? 0x00ff00 : 0xff0000);
            onChange(initialState);
        });
        return labelText;
    }
    unlockAllNotes() {
        const collectedNotes = Object.values(Notes.getNotesContent()).map((note) => note.content);
        this.registry.set('collectedNotes', collectedNotes);
        console.log('All notes unlocked:', collectedNotes);
    }
}
