import Phaser from 'phaser';
export default class StoryScene extends Phaser.Scene {
    storyLines;
    currentLineIndex;
    storyText;
    continueButton;
    skipButton;
    gameoverAudio;
    storyAudio;
    totalStoryDuration = 80000; // Total duration for the story in milliseconds (80 seconds)
    constructor() {
        super({ key: 'StoryScene' });
        this.storyLines = [
            "In ancient Crete, there was a powerful king named Minos. He harbored a terrible secret.",
            "His wife had given birth to a fearsome creature, half-man, half-bull—the Minotaur.",
            "To imprison this beast, Minos commanded the brilliant inventor Daedalus to create a labyrinth, an inescapable maze so intricate that no one who entered could ever find their way out.",
            "",
            "The Minotaur roamed these dark, winding passages, its only purpose to guard the maze and eliminate any who dared enter.",
            "For years, many brave souls tried to conquer the labyrinth, but all fell prey to its deadly traps and the monstrous guardian within.",
            "",
            "Now, you stand at the entrance to this ancient maze. The echoes of lost adventurers linger, and the distant roar of the Minotaur chills the air.",
            "Your quest is to navigate the treacherous paths, outsmart the traps, and avoid the relentless Minotaur.",
            "Can you uncover the hidden secrets, gather the tools you need, and ultimately escape the labyrinth’s clutches?",
        ];
        this.currentLineIndex = 0;
    }
    create() {
        const { width, height } = this.scale;
        // Play the gameover audio in a loop
        this.gameoverAudio = this.sound.add('gameover', { loop: true, volume: 0.5 });
        this.gameoverAudio.play();
        // Play the story audio
        this.storyAudio = this.sound.add('story');
        this.storyAudio.play();
        // Add a background rectangle
        this.add.rectangle(0, 0, width, height, 0x000000).setOrigin(0);
        // Add the story text
        this.storyText = this.add.text(width / 2, height / 2, '', {
            fontSize: '24px', // Increase the font size
            color: '#fff',
            align: 'left',
            wordWrap: { width: width - 100 },
        }).setOrigin(0.5, 0.5); // Center the text vertically and horizontally
        // Add the title
        const title = this.add.text(width / 2, 50, 'The Labyrinth of the Minotaur', {
            fontSize: '36px', // Increase the title font size
            color: '#fff',
            align: 'center'
        }).setOrigin(0.5);
        // Add the continue button
        this.continueButton = this.add.text(width / 2, height - 100, 'Continue', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();
        this.continueButton.setAlpha(0);
        this.continueButton.on('pointerdown', () => {
            this.scene.start('LevelPickingScene');
        });
        // Add the skip button
        this.skipButton = this.add.text(width / 2, height - 50, 'Skip', {
            fontSize: '20px',
            color: '#fff',
            backgroundColor: '#000',
            padding: { x: 10, y: 5 }
        }).setOrigin(0.5).setInteractive();
        this.skipButton.on('pointerdown', () => {
            this.gameoverAudio.stop();
            this.storyAudio.stop();
            this.scene.start('LevelPickingScene');
        });
        this.time.delayedCall(1000, () => {
            this.showNextLine();
        });
    }
    showNextLine() {
        if (this.currentLineIndex >= this.storyLines.length) {
            this.time.delayedCall(1000, () => {
                this.continueButton.setAlpha(1);
            });
            return;
        }
        const line = this.storyLines[this.currentLineIndex];
        this.storyText.setText(line);
        const wordCount = line.split(' ').length;
        const lineDuration = (wordCount / this.getTotalWordCount()) * this.totalStoryDuration;
        this.tweens.add({
            targets: this.storyText,
            alpha: 1,
            duration: 1500,
            onComplete: () => {
                this.time.delayedCall(lineDuration - 3000, () => {
                    this.tweens.add({
                        targets: this.storyText,
                        alpha: 0,
                        duration: 1500,
                        onComplete: () => {
                            this.currentLineIndex++;
                            this.showNextLine();
                        }
                    });
                });
            }
        });
    }
    getTotalWordCount() {
        return this.storyLines.reduce((count, line) => count + line.split(' ').length, 0);
    }
}
