import Phaser from 'phaser';
export default class MainMenuScene extends Phaser.Scene {
    mainMenuMusic;
    player; // Reference to the player
    constructor() {
        super('MainMenuScene');
    }
    preload() {
        this.load.image('titlescreen', 'assets/tiles/titlescreen.jpg'); // Load your background image
    }
    create() {
        const { width, height } = this.scale;
        // Add background image
        this.add.image(width / 2, height / 2, 'titlescreen').setOrigin(0.5).setDisplaySize(width, height);
        // Add title text
        this.add.text(width / 2, height / 4, 'Minotaur Madness', {
            fontSize: '32px',
            color: '#fff'
        }).setOrigin(0.5);
        // Add New Game button
        const newGameButton = this.add.text(width / 2, height / 2, 'Enter Maze', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setInteractive();
        newGameButton.on('pointerdown', () => {
            this.resetGameState(); // Call resetGameState
            this.scene.start('LevelPickingScene');
        });
        // Add Story button
        const storyButton = this.add.text(width / 2, height / 2 + 50, 'Story', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setInteractive();
        storyButton.on('pointerdown', () => {
            this.scene.start('StoryScene');
        });
        // Add Instructions button
        const instructionsButton = this.add.text(width / 2, height / 2 + 100, 'Instructions', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setInteractive();
        instructionsButton.on('pointerdown', () => {
            this.scene.start('InstructionsScene');
        });
        // Add Options button
        const optionsButton = this.add.text(width / 2, height / 2 + 150, 'Options', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setInteractive();
        optionsButton.on('pointerdown', () => {
            this.scene.start('OptionsScene');
        });
        // Add Credits button
        const creditsButton = this.add.text(width / 2, height / 2 + 200, 'Credits', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setInteractive();
        creditsButton.on('pointerdown', () => {
            // Add logic for credits here
        });
        // Add Close button
        const closeButton = this.add.text(width / 2, height / 2 + 250, 'Close', {
            fontSize: '24px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setInteractive();
        closeButton.on('pointerdown', () => {
            this.closeGame();
        });
        // Add and play main menu music
        this.mainMenuMusic = this.sound.add('mainmenu', {
            loop: true,
            volume: 0.05
        });
        this.mainMenuMusic.play();
    }
    shutdown() {
        this.mainMenuMusic.stop();
    }
    closeGame() {
        if (window.confirm("Are you sure you want to close the game?")) {
            window.close();
        }
    }
    resetGameState() {
        // Assuming you have a reference to the player instance
        if (this.player) {
            this.player.resetAll(); // Call resetAll on the player
        }
        // Reset other game-related states
        this.registry.set('totalScore', 0);
        this.registry.set('currentLevel', 1);
        this.registry.set('completedLevels', []);
        this.registry.set('playerData', { gold: 0, inventory: {}, collectedNotes: [] });
        this.registry.set('level', 1);
        // Clear any other necessary state
        this.registry.set('maze', null);
        console.log('Game state reset');
        // Ensure other related scenes (like LevelPicking) are properly reset
        this.scene.stop('LevelPickingScene');
    }
}
