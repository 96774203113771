import { TILE_SIZE } from '../utils/constants';
import { shuffleArray } from '../utils/helpers';
export default class EntranceExit {
    scene;
    player;
    occupiedPositions;
    entrance;
    exit;
    door = null;
    key = null;
    sounds;
    permanentlyVisibleObjects; // Track permanently visible objects
    constructor(scene, player, occupiedPositions, sounds) {
        this.scene = scene;
        this.player = player;
        this.occupiedPositions = occupiedPositions;
        this.sounds = sounds;
        this.permanentlyVisibleObjects = new Set();
        if (this.scene.input.keyboard) {
            this.scene.input.keyboard.on('keydown-E', this.tryOpenDoor, this);
        }
    }
    addEntranceExit(deadEnds) {
        if (deadEnds.length < 2)
            return;
        const [entrancePos, exitPos] = shuffleArray(deadEnds).slice(0, 2);
        // Add entrance
        this.entrance = this.scene.physics.add.sprite(entrancePos.x * TILE_SIZE, entrancePos.y * TILE_SIZE, 'up');
        this.entrance.setOrigin(0, 0);
        this.entrance.setAlpha(0); // Make entrance invisible initially
        this.scene.physics.add.overlap(this.player, this.entrance, this.enterEntrance, undefined, this);
        // Add exit
        this.exit = this.scene.physics.add.sprite(exitPos.x * TILE_SIZE, exitPos.y * TILE_SIZE, 'down');
        this.exit.setOrigin(0, 0);
        this.exit.setAlpha(0); // Make exit invisible initially
        this.scene.physics.add.overlap(this.player, this.exit, this.enterExit, undefined, this);
        // Add door next to the exit
        const doorPos = this.findAdjacentOpenTile(exitPos);
        if (doorPos) {
            this.door = this.scene.physics.add.sprite(doorPos.x * TILE_SIZE, doorPos.y * TILE_SIZE, 'door');
            this.door.setOrigin(0, 0);
            this.door.setImmovable(true); // Make the door immovable
            this.door.setAlpha(0); // Make door invisible initially
            this.scene.physics.add.collider(this.player, this.door); // Add collider for the door
            console.log(`Door spawned at (${doorPos.x}, ${doorPos.y})`); // Log door spawn
        }
        else {
            console.error("Failed to find a valid position for the door. Restarting the scene...");
            this.restartScene();
            return;
        }
        // Add key next to the player
        const keyPos = this.findAdjacentOpenTile({ x: Math.floor(this.player.x / TILE_SIZE), y: Math.floor(this.player.y / TILE_SIZE) });
        if (keyPos) {
            this.key = this.scene.physics.add.sprite(keyPos.x * TILE_SIZE, keyPos.y * TILE_SIZE, 'key');
            this.key.setOrigin(0, 0);
            this.key.setAlpha(0); // Make key invisible initially
            this.scene.physics.add.overlap(this.player, this.key, this.collectKey, undefined, this);
        }
        this.occupiedPositions.add(`${entrancePos.x},${entrancePos.y}`);
        this.occupiedPositions.add(`${exitPos.x},${exitPos.y}`);
        if (doorPos)
            this.occupiedPositions.add(`${doorPos.x},${doorPos.y}`);
        if (keyPos)
            this.occupiedPositions.add(`${keyPos.x},${keyPos.y}`);
    }
    restartScene() {
        this.sounds.stopAllSounds();
        try {
            this.scene.time.delayedCall(100, () => {
                this.scene.scene.restart({
                    level: this.scene.registry.get('level'),
                    totalScore: this.scene.registry.get('totalScore'),
                    playerData: this.scene.registry.get('playerData')
                });
                console.log('MainScene successfully restarted.');
            });
        }
        catch (error) {
            console.error('Failed to restart MainScene, redirecting to MainMenuScene.', error);
            this.scene.scene.start('MainMenuScene');
        }
    }
    enterEntrance() {
        console.log("Entered entrance");
        // Logic to handle entering the entrance (e.g., start next level)
    }
    enterExit() {
        this.scene.registry.values.totalScore += this.player.getScore();
        this.scene.levelComplete();
    }
    tryOpenDoor() {
        const playerTileX = Math.floor(this.player.x / TILE_SIZE);
        const playerTileY = Math.floor(this.player.y / TILE_SIZE);
        if (this.door &&
            ((playerTileX === Math.floor(this.door.x / TILE_SIZE) && Math.abs(playerTileY - Math.floor(this.door.y / TILE_SIZE)) === 1) ||
                (playerTileY === Math.floor(this.door.y / TILE_SIZE) && Math.abs(playerTileX - Math.floor(this.door.x / TILE_SIZE)) === 1))) {
            this.openDoor();
        }
    }
    openDoor() {
        if (this.player.hasKey && this.door) {
            this.door.setTexture('dooropen');
            if (this.door.body) {
                this.door.body.enable = false; // Disable the door's physics body
            }
            this.sounds.playGateOpeningSound();
        }
    }
    collectKey() {
        console.log("Key collected");
        if (this.key) {
            this.player.hasKey = true;
            this.player.addItem('key'); // Add key to inventory
            this.key.destroy();
            this.sounds.playKeyPickupSound();
            this.sounds.playRandomKeySound();
        }
    }
    findAdjacentOpenTile(pos) {
        const directions = [
            { x: 0, y: -1 }, // Up
            { x: 0, y: 1 }, // Down
            { x: -1, y: 0 }, // Left
            { x: 1, y: 0 } // Right
        ];
        for (const dir of directions) {
            const checkX = pos.x + dir.x;
            const checkY = pos.y + dir.y;
            if (this.scene.physics.world.bounds.contains(checkX * TILE_SIZE, checkY * TILE_SIZE) &&
                !this.occupiedPositions.has(`${checkX},${checkY}`) &&
                this.scene.children.getByName(`tile-${checkX}-${checkY}`)) {
                return { x: checkX, y: checkY };
            }
        }
        return null;
    }
    updateVisibility(fovTiles) {
        const updateObjectVisibility = (object) => {
            if (object) {
                const tileX = Math.floor(object.x / TILE_SIZE);
                const tileY = Math.floor(object.y / TILE_SIZE);
                const objectKey = `${tileX},${tileY}`;
                if (fovTiles[tileY] && fovTiles[tileY][tileX]) {
                    // If the object is within FOV, make it visible
                    object.setAlpha(1);
                    // If the player has the magic scroll and sees the object, make it permanently visible
                    if (this.player.hasMagicScroll) {
                        this.permanentlyVisibleObjects.add(objectKey);
                    }
                }
                else if (this.permanentlyVisibleObjects.has(objectKey)) {
                    // If the object was already discovered after getting the magic scroll, keep it visible
                    object.setAlpha(1);
                }
                else {
                    // Otherwise, hide the object if it's outside of the FOV
                    object.setAlpha(0);
                }
            }
        };
        updateObjectVisibility(this.entrance);
        updateObjectVisibility(this.exit);
        updateObjectVisibility(this.door);
        updateObjectVisibility(this.key);
    }
    getDoorPosition() {
        if (this.door) {
            return { x: Math.floor(this.door.x / TILE_SIZE), y: Math.floor(this.door.y / TILE_SIZE) };
        }
        return null;
    }
    getExitPosition() {
        if (this.exit) {
            return { x: Math.floor(this.exit.x / TILE_SIZE), y: Math.floor(this.exit.y / TILE_SIZE) };
        }
        return null;
    }
}
