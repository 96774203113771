import Phaser from 'phaser';
import { TILE_SIZE } from '../utils/constants';
export default class MagicScroll extends Phaser.Physics.Arcade.Sprite {
    isVisiblePermanently = false;
    constructor(scene, x, y) {
        super(scene, x, y, 'magicscroll');
        scene.add.existing(this);
        scene.physics.add.existing(this);
        // Set sprite properties
        this.setOrigin(0.5);
        this.setScale(0.5);
        // Enable collision detection
        if (this.body) {
            this.body.setSize(TILE_SIZE, TILE_SIZE);
            this.body.setOffset(16, 16);
        }
        this.setName('magicscroll');
    }
    static addMagicScroll(scene, player, occupiedPositions, sounds, fovTiles) {
        let scrollX, scrollY;
        const maze = scene.registry.get('maze');
        do {
            scrollX = Phaser.Math.Between(1, maze[0].length - 2);
            scrollY = Phaser.Math.Between(1, maze.length - 2);
        } while (maze[scrollY][scrollX] !== 0 || occupiedPositions.has(`${scrollX},${scrollY}`));
        const scroll = new MagicScroll(scene, scrollX * TILE_SIZE + TILE_SIZE / 2, scrollY * TILE_SIZE + TILE_SIZE / 2);
        scene.physics.add.overlap(player, scroll, () => {
            if (player) {
                player.hasMagicScroll = true;
                scroll.isVisiblePermanently = true; // Mark scroll as permanently visible
                scroll.setAlpha(1); // Ensure it's visible when picked up
            }
            if (scroll) {
                scroll.destroy(); // Destroy the scroll after pickup
            }
            if (sounds) {
                sounds.playYarnPickupSound();
                sounds.playRandomScrollSound(); // Play a random scroll sound on pickup
            }
        });
        scene.events.on('update', () => {
            scroll.updateVisibility(fovTiles);
        });
        occupiedPositions.add(`${scrollX},${scrollY}`);
    }
    updateVisibility(fovTiles) {
        const tileX = Math.floor(this.x / TILE_SIZE);
        const tileY = Math.floor(this.y / TILE_SIZE);
        if (this.isVisiblePermanently || (fovTiles[tileY] && fovTiles[tileY][tileX])) {
            this.setAlpha(1); // Make the scroll visible if permanently visible or in FOV
        }
        else {
            this.setAlpha(0); // Otherwise, make it invisible
        }
    }
}
