import Phaser from 'phaser';
import { TILE_SIZE } from '../utils/constants';
import EasyStar from 'easystarjs';
var MinotaurState;
(function (MinotaurState) {
    MinotaurState[MinotaurState["PATROLLING"] = 0] = "PATROLLING";
    MinotaurState[MinotaurState["CHASING"] = 1] = "CHASING";
    MinotaurState[MinotaurState["FOLLOWING_TRAIL"] = 2] = "FOLLOWING_TRAIL";
    MinotaurState[MinotaurState["IDLE"] = 3] = "IDLE";
    MinotaurState[MinotaurState["UNSTUCK"] = 4] = "UNSTUCK";
})(MinotaurState || (MinotaurState = {}));
export default class Minotaur extends Phaser.Physics.Arcade.Sprite {
    player;
    sounds;
    pathfinder;
    pathGraphics;
    trailGraphics;
    currentPath = [];
    trail = [];
    stuckCounter = 0;
    maxStuckFrames = 30;
    lastPosition = { x: 0, y: 0 };
    currentState = MinotaurState.IDLE;
    lastPathfindingTime = 0;
    pathfindingCooldown = 500;
    stuckCheckInterval = 100;
    changeDirectionDelay = 200;
    hasContacted = false;
    unstuckStartPosition = null;
    unstuckMoveThreshold = TILE_SIZE * 2; // Amount of movement required to consider unstuck
    constructor(scene, x, y, texture, player, sounds) {
        super(scene, x, y, texture);
        this.player = player;
        this.sounds = sounds;
        this.scene.add.existing(this);
        this.scene.physics.add.existing(this);
        const body = this.body;
        body.setCircle(this.width * 0.30);
        body.setOffset((this.width - body.width) / 2, (this.height - body.height) / 2);
        body.setCollideWorldBounds(false);
        this.setScale(0.90);
        this.pathfinder = new EasyStar.js();
        this.pathfinder.setGrid(this.getMazeGrid());
        this.pathfinder.setAcceptableTiles([0]);
        this.pathGraphics = this.scene.add.graphics();
        this.pathGraphics.setDepth(100);
        this.scene.time.delayedCall(1000, () => {
            this.transitionToState(MinotaurState.PATROLLING);
        });
        // Initialize trailGraphics for debugging
        this.trailGraphics = this.scene.add.graphics();
        this.trailGraphics.setDepth(101);
        this.scene.time.delayedCall(1000, () => {
            this.transitionToState(MinotaurState.PATROLLING);
        });
        this.scene.time.addEvent({
            delay: this.stuckCheckInterval,
            loop: true,
            callback: this.checkIfStuck,
            callbackScope: this
        });
    }
    update() {
        if (!this.active)
            return;
        this.updateTrail();
        this.drawTrail();
        switch (this.currentState) {
            case MinotaurState.CHASING:
                this.updateChasingBehavior();
                break;
            case MinotaurState.PATROLLING:
                this.updatePatrollingBehavior();
                break;
            case MinotaurState.FOLLOWING_TRAIL:
                this.updateFollowingTrailBehavior();
                break;
            case MinotaurState.IDLE:
                this.updateIdleBehavior();
                break;
            case MinotaurState.UNSTUCK:
                this.updateUnstuckBehavior();
                break;
        }
    }
    updateTrail() {
        const timestamp = this.scene.time.now;
        const position = { x: this.player.x, y: this.player.y, timestamp: timestamp };
        this.trail.push(position);
        // Keep breadcrumbs for 30 seconds, removing the oldest ones
        this.trail = this.trail.filter(pos => (timestamp - pos.timestamp) <= 30000);
    }
    drawTrail() {
        this.trailGraphics.clear();
        // Check if the draw paths option is enabled
        const isDrawPathsEnabled = this.scene.registry.get('isDrawPathsEnabled') || false;
        if (isDrawPathsEnabled) {
            // Draw circles at each trail point
            for (let i = 0; i < this.trail.length; i++) {
                const pos = this.trail[i];
                this.trailGraphics.fillStyle(0xff0000, 1); // Red color for the trail points
                this.trailGraphics.fillCircle(pos.x, pos.y, 3); // Draw a small circle (radius 3)
            }
            // Optionally draw lines connecting the trail points
            this.trailGraphics.strokePath();
        }
    }
    updateIdleBehavior() {
        console.log('Minotaur is idle.');
    }
    getMazeGrid() {
        const maze = this.scene.registry.get('maze');
        return maze.map((row) => row.map((cell) => (cell === 1 ? 1 : 0)));
    }
    hasLineOfSightToPlayer() {
        const tileSize = TILE_SIZE;
        const startX = this.x;
        const startY = this.y;
        const endX = this.player.x;
        const endY = this.player.y;
        const ray = new Phaser.Geom.Line(startX, startY, endX, endY);
        const walls = this.scene.physics.world.staticBodies.entries.filter(body => body.gameObject.name.startsWith('wall'));
        let hasClearLOS = true;
        walls.forEach(wall => {
            const wallBounds = wall.gameObject.getBounds();
            if (Phaser.Geom.Intersects.LineToRectangle(ray, wallBounds)) {
                hasClearLOS = false;
            }
        });
        const distance = Phaser.Math.Distance.Between(startX, startY, endX, endY);
        if (distance > tileSize * 6) {
            hasClearLOS = false;
        }
        // console.log(`LOS Check: Clear ${hasClearLOS}, Distance: ${distance}`);
        return hasClearLOS;
    }
    updateUnstuckBehavior() {
        if (this.unstuckStartPosition) {
            const distanceMoved = Phaser.Math.Distance.Between(this.unstuckStartPosition.x, this.unstuckStartPosition.y, this.x, this.y);
            if (distanceMoved >= this.unstuckMoveThreshold) {
                console.log('Minotaur has successfully moved out of stuck position.');
                this.unstuckStartPosition = null; // Reset the start position
                this.transitionToState(MinotaurState.PATROLLING); // Switch back to patrolling
            }
        }
    }
    moveToNextTile(targetX, targetY, speed) {
        const deltaX = targetX - this.x;
        const deltaY = targetY - this.y;
        const ray = new Phaser.Geom.Line(this.x, this.y, targetX, targetY);
        const walls = this.scene.physics.world.staticBodies.entries.filter(body => body.gameObject.name.startsWith('wall'));
        let hasClearPath = true;
        walls.forEach(wall => {
            const wallBounds = wall.gameObject.getBounds();
            if (Phaser.Geom.Intersects.LineToRectangle(ray, wallBounds)) {
                hasClearPath = false;
            }
        });
        if (hasClearPath) {
            this.scene.physics.moveTo(this, targetX, targetY, speed);
        }
        else {
            this.setVelocity(0);
            const avoidDirection = new Phaser.Math.Vector2(deltaX, deltaY).normalize().scale(TILE_SIZE / 2);
            this.scene.physics.moveTo(this, this.x + avoidDirection.x, this.y + avoidDirection.y, speed);
        }
        if (Math.abs(this.x - targetX) < TILE_SIZE / 4 && Math.abs(this.y - targetY) < TILE_SIZE / 4) {
            this.setVelocity(0);
            // console.log(`Reached target (${targetX}, ${targetY})`);
        }
    }
    updateChasingBehavior() {
        if (!this.active)
            return;
        console.log('Minotaur is chasing the player.');
        if (this.hasLineOfSightToPlayer()) {
            if (!this.hasContacted) {
                this.sounds.playMinotaurContactSound();
                this.scene.time.delayedCall(500, () => {
                    this.sounds.playMonsterLOSSound();
                    this.sounds.playChaseLoopSound();
                    this.startChasingPlayer();
                });
                this.hasContacted = true;
            }
            this.trail.push({ x: this.player.x, y: this.player.y, timestamp: this.scene.time.now });
            this.moveDirectlyTowardsPlayer();
        }
        else {
            // Lay a final breadcrumb at the last known player position
            this.trail.push({ x: this.player.x, y: this.player.y, timestamp: this.scene.time.now });
            console.log('Lost sight of player, moving to last known position.');
            this.sounds.stopChaseLoopSound();
            this.moveToNextTile(this.player.x, this.player.y, this.player.getSpeed() * 0.5);
            this.transitionToState(MinotaurState.FOLLOWING_TRAIL);
        }
    }
    updateFollowingTrailBehavior() {
        if (!this.active || this.trail.length === 0)
            return;
        // Find the closest breadcrumb to the Minotaur's current location
        const closestBreadcrumb = this.trail.reduce((closest, current) => {
            const distanceToCurrent = Phaser.Math.Distance.Between(this.x, this.y, current.x, current.y);
            const distanceToClosest = Phaser.Math.Distance.Between(this.x, this.y, closest.x, closest.y);
            return distanceToCurrent < distanceToClosest ? current : closest;
        }, this.trail[0]);
        // Move towards the closest breadcrumb
        this.moveToNextTile(closestBreadcrumb.x, closestBreadcrumb.y, this.player.getSpeed() * 0.5);
        this.sounds.playMinotaurFoundTrail();
        // Remove the closest breadcrumb from the trail once it's been reached
        if (Phaser.Math.Distance.Between(this.x, this.y, closestBreadcrumb.x, closestBreadcrumb.y) < TILE_SIZE / 2) {
            this.trail.splice(this.trail.indexOf(closestBreadcrumb), 1);
        }
        // Recheck LOS and transition to chasing or patrolling as needed
        if (this.hasLineOfSightToPlayer()) {
            console.log('Player back in sight, switching to chasing.');
            this.transitionToState(MinotaurState.CHASING);
        }
        else if (this.trail.length === 0) {
            console.log('Trail exhausted, returning to patrol.');
            this.transitionToState(MinotaurState.PATROLLING);
        }
    }
    startChasingPlayer() {
        if (this.hasLineOfSightToPlayer()) {
            this.trail.push({ x: this.player.x, y: this.player.y, timestamp: this.scene.time.now });
            this.moveDirectlyTowardsPlayer();
        }
    }
    moveDirectlyTowardsPlayer() {
        const deltaX = this.player.x - this.x;
        const deltaY = this.player.y - this.y;
        this.setVelocity(0);
        this.scene.time.delayedCall(this.changeDirectionDelay, () => {
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                this.setVelocityX(Math.sign(deltaX) * this.player.getSpeed() * 0.6);
            }
            else {
                this.setVelocityY(Math.sign(deltaY) * this.player.getSpeed() * 0.6);
            }
        }, [], this);
    }
    getNearbyTrailPoint() {
        const proximityThreshold = TILE_SIZE / 2; // Adjust this value to change how close the Minotaur needs to be
        for (let i = 0; i < this.trail.length; i++) {
            const trailPoint = this.trail[i];
            if (Phaser.Math.Distance.Between(this.x, this.y, trailPoint.x, trailPoint.y) < proximityThreshold) {
                return trailPoint;
            }
        }
        return null;
    }
    updatePatrollingBehavior() {
        if (!this.active)
            return;
        // Check if the Minotaur is close to any trail points
        const trailPoint = this.getNearbyTrailPoint();
        if (trailPoint) {
            console.log('Trail found nearby, switching to trail following.');
            this.transitionToState(MinotaurState.FOLLOWING_TRAIL);
            return;
        }
        // Continue patrolling as usual
        if (this.currentPath.length === 0) {
            this.generateNextPatrolPoint();
        }
        else {
            const target = this.currentPath[0];
            this.moveToNextTile(target.x * TILE_SIZE + TILE_SIZE / 2, target.y * TILE_SIZE + TILE_SIZE / 2, this.player.getSpeed() * 0.3);
            if (Phaser.Math.Distance.Between(this.x, this.y, target.x * TILE_SIZE + TILE_SIZE / 2, target.y * TILE_SIZE + TILE_SIZE / 2) < TILE_SIZE / 4) {
                this.currentPath.shift();
                if (this.currentPath.length === 0) {
                    this.generateNextPatrolPoint();
                }
            }
        }
        if (this.hasLineOfSightToPlayer()) {
            console.log('Player spotted during patrol, switching to chasing.');
            this.transitionToState(MinotaurState.CHASING);
        }
        else {
            this.sounds.stopChaseLoopSound();
        }
    }
    generateNextPatrolPoint() {
        const mazeGrid = this.getMazeGrid();
        const walkableTiles = [];
        for (let y = 0; y < mazeGrid.length; y++) {
            for (let x = 0; x < mazeGrid[y].length; x++) {
                if (mazeGrid[y][x] === 0) {
                    walkableTiles.push({ x, y });
                }
            }
        }
        const randomTile = Phaser.Math.RND.pick(walkableTiles);
        this.findPathAndMoveTo(randomTile.x * TILE_SIZE + TILE_SIZE / 2, randomTile.y * TILE_SIZE + TILE_SIZE / 2, this.player.getSpeed() * 0.3, 0x00ff00);
    }
    checkIfStuck() {
        const currentPosition = { x: Math.round(this.x), y: Math.round(this.y) };
        if (currentPosition.x === this.lastPosition.x && currentPosition.y === this.lastPosition.y) {
            this.stuckCounter++;
            if (this.stuckCounter > this.maxStuckFrames) {
                console.log('Minotaur is stuck, attempting to move randomly.');
                this.unstuckStartPosition = { x: this.x, y: this.y };
                this.transitionToState(MinotaurState.UNSTUCK);
                this.tryToUnstuck();
                this.stuckCounter = 0;
            }
        }
        else {
            this.stuckCounter = 0;
        }
        this.lastPosition = currentPosition;
    }
    tryToUnstuck() {
        const directions = [
            { x: 1, y: 0 },
            { x: -1, y: 0 },
            { x: 0, y: 1 },
            { x: 0, y: -1 }
        ];
        Phaser.Utils.Array.Shuffle(directions);
        for (const direction of directions) {
            const targetX = this.x + direction.x * TILE_SIZE;
            const targetY = this.y + direction.y * TILE_SIZE;
            const tileX = Math.floor(targetX / TILE_SIZE);
            const tileY = Math.floor(targetY / TILE_SIZE);
            const mazeGrid = this.getMazeGrid();
            if (tileX >= 0 && tileX < mazeGrid[0].length && tileY >= 0 && tileY < mazeGrid.length && mazeGrid[tileY][tileX] === 0) {
                console.log(`Attempting to unstuck by moving towards: (${targetX}, ${targetY})`);
                this.moveToNextTile(targetX, targetY, this.player.getSpeed() * 0.5);
                return;
            }
        }
        console.log('Failed to find a clear path, moving to a random valid position.');
        const walkableTiles = this.getWalkableTilesAround();
        if (walkableTiles.length > 0) {
            const randomTile = Phaser.Math.RND.pick(walkableTiles);
            const targetX = randomTile.x * TILE_SIZE + TILE_SIZE / 2;
            const targetY = randomTile.y * TILE_SIZE + TILE_SIZE / 2;
            this.moveToNextTile(targetX, targetY, this.player.getSpeed() * 0.5);
        }
    }
    getWalkableTilesAround() {
        const mazeGrid = this.getMazeGrid();
        const tileX = Math.floor(this.x / TILE_SIZE);
        const tileY = Math.floor(this.y / TILE_SIZE);
        const walkableTiles = [];
        const directions = [
            { x: 1, y: 0 },
            { x: -1, y: 0 },
            { x: 0, y: 1 },
            { x: 0, y: -1 }
        ];
        directions.forEach(dir => {
            const checkX = tileX + dir.x;
            const checkY = tileY + dir.y;
            if (checkX >= 0 && checkX < mazeGrid[0].length && checkY >= 0 && checkY < mazeGrid.length && mazeGrid[checkY][checkX] === 0) {
                walkableTiles.push({ x: checkX, y: checkY });
            }
        });
        return walkableTiles;
    }
    findPathAndMoveTo(x, y, speed, color) {
        const now = this.scene.time.now;
        if (now - this.lastPathfindingTime < this.pathfindingCooldown) {
            return;
        }
        this.lastPathfindingTime = now;
        this.pathfinder.findPath(Math.floor(this.x / TILE_SIZE), Math.floor(this.y / TILE_SIZE), Math.floor(x / TILE_SIZE), Math.floor(y / TILE_SIZE), path => {
            if (path && path.length > 0) {
                this.drawPath(path, color);
                this.currentPath = path.map(p => ({ x: p.x, y: p.y }));
            }
            else {
                console.log('No valid path found.');
                this.currentPath = [];
            }
        });
        this.pathfinder.calculate();
    }
    drawPath(path, color) {
        // Check if the draw paths option is enabled
        const isDrawPathsEnabled = this.scene.registry.get('isDrawPathsEnabled') || false;
        if (isDrawPathsEnabled) {
            this.pathGraphics.clear();
            this.pathGraphics.lineStyle(2, color, 1);
            this.pathGraphics.setDepth(100);
            this.pathGraphics.setVisible(true);
            this.pathGraphics.setAlpha(1);
            this.pathGraphics.beginPath();
            for (let i = 0; i < path.length - 1; i++) {
                const startX = path[i].x * TILE_SIZE + TILE_SIZE / 2;
                const startY = path[i].y * TILE_SIZE + TILE_SIZE / 2;
                const endX = path[i + 1].x * TILE_SIZE + TILE_SIZE / 2;
                const endY = path[i + 1].y * TILE_SIZE + TILE_SIZE / 2;
                this.pathGraphics.moveTo(startX, startY);
                this.pathGraphics.lineTo(endX, endY);
            }
            this.pathGraphics.strokePath();
        }
        else {
            // If drawing paths is disabled, ensure the pathGraphics are cleared
            this.pathGraphics.clear();
        }
    }
    transitionToState(newState) {
        if (this.currentState !== newState) {
            console.log(`Transitioning from ${MinotaurState[this.currentState]} to ${MinotaurState[newState]}`);
            this.currentState = newState;
            if (newState === MinotaurState.PATROLLING) {
                this.generateNextPatrolPoint();
            }
            else if (newState === MinotaurState.CHASING) {
                if (this.hasLineOfSightToPlayer()) {
                    this.startChasingPlayer();
                }
            }
        }
    }
}
