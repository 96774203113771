import Phaser from 'phaser';
export default class StoreScene extends Phaser.Scene {
    player;
    storeText;
    goldText;
    sounds;
    inventoryItems;
    inventoryTexts;
    // Store configuration for prices and stock levels
    storeConfig = {
        sword: { price: 200, stock: 2 },
        shield: { price: 50, stock: 2 },
        minimap: { price: 100, stock: 1 },
        magicscroll: { price: 100, stock: 1 },
        torch: { price: 20, stock: 3 },
        portalpotion: { price: 150, stock: 1 }
    };
    constructor() {
        super('StoreScene');
        this.inventoryItems = {};
        this.inventoryTexts = {};
    }
    init(data) {
        this.player = data.player;
        this.sounds = data.sounds;
        this.player.disableInput();
    }
    create() {
        const { width, height } = this.scale;
        this.add.rectangle(0, 0, width, height, 0x000000, 0.5).setOrigin(0);
        // Play a random store sound
        if (this.sounds) {
            this.sounds.playRandomStoreSound();
        }
        this.storeText = this.add.text(width / 2, height / 4, 'Store', {
            fontSize: '32px',
            color: '#fff'
        }).setOrigin(0.5);
        this.goldText = this.add.text(20, 20, `Gold: ${this.player.getGold()}`, {
            fontSize: '16px',
            color: '#fff'
        }).setScrollFactor(0);
        // Create store items
        this.createStoreItem('sword', width / 2 - 200, height / 2);
        this.createStoreItem('shield', width / 2 - 100, height / 2);
        this.createStoreItem('minimap', width / 2, height / 2);
        this.createStoreItem('magicscroll', width / 2 + 100, height / 2);
        this.createStoreItem('torch', width / 2 + 200, height / 2);
        this.createStoreItem('portalpotion', width / 2 + 300, height / 2);
        // Close store on E key press
        if (this.input.keyboard) {
            this.input.keyboard.on('keydown-E', this.toggleStore, this);
        }
    }
    createStoreItem(item, x, y) {
        const config = this.storeConfig[item];
        this.inventoryItems[item] = this.add.image(x, y, item).setInteractive();
        this.inventoryTexts[item] = this.add.text(x, y + 40, `${config.price} Gold\n(${config.stock})`, {
            fontSize: '16px',
            color: '#fff',
            align: 'center'
        }).setOrigin(0.5);
        this.inventoryItems[item].on('pointerdown', () => {
            if (this.player.getGold() >= config.price && config.stock > 0) {
                this.player.addItem(item);
                this.player.updateGold(-config.price);
                this.updateGoldText();
                config.stock -= 1;
                this.updateInventoryText(item);
                if (config.stock === 0) {
                    this.inventoryItems[item].disableInteractive();
                    this.inventoryItems[item].setTint(0x808080);
                }
            }
        });
    }
    updateGoldText() {
        this.goldText.setText(`Gold: ${this.player.getGold()}`);
    }
    updateInventoryText(item) {
        const config = this.storeConfig[item];
        this.inventoryTexts[item].setText(`${config.price} Gold\n(${config.stock})`);
    }
    toggleStore() {
        this.player.enableInput();
        this.scene.stop('StoreScene');
    }
}
