import Phaser from 'phaser';
import Sounds from '../utils/Sounds'; // Import Sounds class
export default class LevelCompleteScene extends Phaser.Scene {
    level;
    score;
    totalScore;
    sounds; // Reference to the Sounds instance
    constructor() {
        super({ key: 'LevelCompleteScene' });
    }
    init(data) {
        this.level = data.level;
        this.score = data.score;
        this.totalScore = data.totalScore;
    }
    create() {
        const { width, height } = this.scale;
        // Initialize Sounds
        this.sounds = new Sounds(this);
        this.time.delayedCall(2000, () => {
            this.sounds.playLevelCompleteNarration();
        });
        this.add.text(width / 2, height / 2 - 100, `Level ${this.level} Complete!`, {
            fontSize: '48px',
            color: '#fff'
        }).setOrigin(0.5);
        this.add.text(width / 2, height / 2 - 50, `Score: ${this.score}`, {
            fontSize: '32px',
            color: '#fff'
        }).setOrigin(0.5);
        this.add.text(width / 2, height / 2, `Total Score: ${this.totalScore}`, {
            fontSize: '32px',
            color: '#fff'
        }).setOrigin(0.5);
        const continueButton = this.add.text(width / 2, height / 2 + 150, 'Continue', {
            fontSize: '32px',
            color: '#fff',
            backgroundColor: '#000'
        }).setOrigin(0.5).setPadding(10).setInteractive();
        continueButton.on('pointerdown', () => {
            if (this.level >= 10) {
                this.scene.start('GameCompleteScene', { totalScore: this.totalScore });
            }
            else {
                this.scene.start('LevelPickingScene', {
                    level: this.level + 1,
                    totalScore: this.totalScore
                });
                console.log('Player Data:', this.registry.get('playerData'));
            }
        });
        const messageText = this.add.text(width / 2, height / 2 + 250, "You venture deeper into the Labyrinth's depths. The shadows grow darker, and the Minotaur's hunt intensifies. Press onward into the heart of the Labyrinth", {
            fontSize: '24px',
            color: '#fff',
            align: 'center',
            wordWrap: { width: width - 40 }
        }).setOrigin(0.5).setAlpha(0); // Initially invisible
        // Fade-in effect
        this.tweens.add({
            targets: messageText,
            alpha: 1,
            duration: 8000,
            ease: 'Power2'
        });
    }
}
