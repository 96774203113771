import Phaser from 'phaser';
export default class JoystickScene extends Phaser.Scene {
    player;
    joystick;
    constructor() {
        super('JoystickScene');
    }
    init(data) {
        this.player = data.player; // Store the player reference
    }
    create() {
        const { width, height } = this.scale;
        const joystickPlugin = this.plugins.get('rexVirtualJoystick');
        if (!joystickPlugin) {
            console.error("Virtual Joystick Plugin not found.");
            return;
        }
        // @ts-ignore: Suppress TypeScript error
        this.joystick = joystickPlugin.add(this, {
            x: this.scale.width - 100, // This will be updated in the resize event
            y: this.scale.height - 100,
            radius: 60,
            base: this.add.circle(0, 0, 60, 0x888888).setDepth(1000), // Set depth
            thumb: this.add.circle(0, 0, 30, 0xcccccc).setDepth(1000), // Set depth
            dir: '8dir',
            forceMin: 16,
            fixed: true,
        });
        this.joystick.createCursorKeys();
        // Update the joystick position on window resize
        this.scale.on('resize', this.updateJoystickPosition, this);
        // Pass the joystick reference to the player
        this.player.setJoystick(this.joystick);
        // Add buttons
        this.createButton('yarn', 50, height - 100, () => this.toggleYarnTrail());
        this.createButton('note', 150, height - 100, () => this.toggleInventory());
        this.createButton('minimap', 250, height - 100, () => this.toggleMinimap());
        this.createButton('torch', 350, height - 100, () => this.toggleTorch());
    }
    updateJoystickPosition() {
        if (this.joystick) {
            this.joystick.setPosition(this.scale.width - 100, this.scale.height - 100);
        }
    }
    createButton(key, x, y, callback) {
        const button = this.add.image(x, y, key)
            .setInteractive()
            .on('pointerdown', callback)
            .setScale(0.7); // Scale the button to appropriate size
        this.add.rectangle(x, y, button.width, button.height)
            .setStrokeStyle(2, 0xffffff); // Add a border to the button
    }
    toggleYarnTrail() {
        this.scene.get('MainScene').events.emit('toggleYarn');
    }
    toggleTorch() {
        this.scene.get('MainScene').events.emit('toggleTorch');
    }
    toggleInventory() {
        this.scene.get('MainScene').events.emit('openInventory');
    }
    toggleMinimap() {
        this.scene.get('MainScene').events.emit('openMinimap');
    }
}
