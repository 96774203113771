import Phaser from 'phaser';
import { GlobalSoundManager } from './GlobalSoundManager';
export default class Sounds {
    scene;
    globalSoundManager;
    isRunningSoundPlaying = false;
    isGrowlPlaying = false;
    isPortalProximitySoundPlaying = false;
    lastMinotaurContactSoundTime = 0;
    lastPageTurnTime = 0;
    pageTurnCooldown = 500;
    // Arrays of sound keys (all are narration sounds)
    levelSounds = ['levels1', 'levels2', 'levels3', 'levels4', 'levels5', 'levels6'];
    mainSounds = ['main1', 'main2', 'main3', 'main4', 'main5', 'main6', 'main7', 'main8'];
    findyarnSounds = ['yarn3', 'yarn4'];
    scrollSounds = ['scroll1', 'scroll2', 'scroll3'];
    mapSounds = ['map1', 'map2', 'map3'];
    yarnSounds = ['yarn1', 'yarn2'];
    torchSounds = ['torch1', 'torch2'];
    goldSounds = ['gold1', 'gold2', 'gold3'];
    storeSounds = ['store1', 'store2', 'store3', 'store4'];
    keySounds = ['key1', 'key2', 'key3'];
    potionSounds = ['potion1', 'potion2', 'potion3', 'potion4'];
    idleSounds = ['idle1', 'idle2', 'idle3', 'idle4', 'idle5', 'idle6', 'idle7', 'idle8', 'idle9', 'idle10'];
    narrationSounds = [
        'note1Narration', 'note2Narration', 'note3Narration', 'note4Narration',
        'note5Narration', 'note6Narration', 'note7Narration', 'note8Narration',
        'note9Narration', 'note10Narration'
    ];
    // Individual sound variables
    runningSound = 'running';
    minotaurContactSound = 'minotaurcontact';
    growlSound = 'growl';
    minotaurisnear = 'minotaurisnear';
    minotaurisnear2 = 'minotaurisnear2';
    minotaurtrail = 'minotaurtrail';
    portalTransferSound = 'portaltransfer';
    portalProximitySound = 'portalisnear';
    chaseLoopSound = 'chaseloop';
    monsterLOSSound = 'monsterLOS';
    pageTurnSound = 'pageturn';
    pageTurnSound2 = 'pageturn2';
    shieldSound = 'shield';
    swordSound = 'sword';
    gamecompleteSound = 'gamecompletenarration';
    gameoverSound = 'gameovernarration';
    levelCompleteNarrationSound = 'levelcompletenarration';
    torchuseSound = 'torchuse';
    torchonSound = 'torchon';
    keyPickupSound = 'key';
    gateOpeningSound = 'gate';
    chestOpenSound = 'chest';
    coinPickupSound = 'coin';
    yarnPickupSound = 'yarnpickup';
    constructor(scene) {
        this.scene = scene;
        this.globalSoundManager = new GlobalSoundManager(scene);
        // Preload all sounds into GlobalSoundManager
        this.preloadSounds();
    }
    preloadSounds() {
        // Preload all sounds as narration where applicable
        const allNarrationSounds = [
            ...this.levelSounds, ...this.mainSounds, ...this.findyarnSounds, ...this.scrollSounds,
            ...this.mapSounds, ...this.yarnSounds, ...this.torchSounds, ...this.goldSounds,
            ...this.storeSounds, ...this.keySounds, ...this.potionSounds, ...this.idleSounds,
            ...this.narrationSounds, this.levelCompleteNarrationSound //
        ];
        allNarrationSounds.forEach(key => {
            console.log(`Adding sound: ${key} as narration`);
            this.globalSoundManager.addSound(key, 'narration');
        });
        // Add remaining non-narration sounds
        [
            this.runningSound, this.minotaurContactSound, this.growlSound, this.minotaurisnear,
            this.minotaurisnear2, this.minotaurtrail, this.portalTransferSound, this.portalProximitySound,
            this.chaseLoopSound, this.monsterLOSSound, this.pageTurnSound, this.pageTurnSound2,
            this.shieldSound, this.swordSound, this.gamecompleteSound, this.gameoverSound,
            this.torchuseSound, this.torchonSound, this.keyPickupSound, this.gateOpeningSound,
            this.chestOpenSound, this.coinPickupSound, this.yarnPickupSound
        ].forEach(key => {
            const type = this.getSoundType(key);
            console.log(`Adding sound: ${key} as ${type}`);
            this.globalSoundManager.addSound(key, type);
        });
    }
    getSoundType(key) {
        if (key === this.runningSound || key === this.chaseLoopSound)
            return 'background';
        return 'item';
    }
    playFootstepSound(isMoving) {
        if (isMoving && !this.isRunningSoundPlaying) {
            this.globalSoundManager.playSound(this.runningSound, 'background', { volume: 1.0 });
            this.isRunningSoundPlaying = true;
        }
        else if (!isMoving && this.isRunningSoundPlaying) {
            this.globalSoundManager.stopSound(this.runningSound, 'background');
            this.isRunningSoundPlaying = false;
        }
    }
    playMinotaurContactSound() {
        const currentTime = this.scene.time.now;
        if (currentTime - this.lastMinotaurContactSoundTime > 10000) { // 10 seconds cooldown
            this.globalSoundManager.playSound(this.minotaurContactSound, 'item', { volume: 1.0 });
            this.lastMinotaurContactSoundTime = currentTime;
        }
    }
    playTorchUseSound() {
        this.globalSoundManager.playSound(this.torchuseSound, 'item', { volume: 1.0 });
    }
    playTorchOnSound() {
        this.globalSoundManager.playSound(this.torchonSound, 'item', { volume: 1.0 });
    }
    playMinotaurFoundTrail() {
        this.globalSoundManager.playSound(this.minotaurtrail, 'item', { volume: 1.0 });
    }
    // Play Page Turn Sound with Cooldown
    playPageTurnSound() {
        const currentTime = this.scene.time.now;
        if (currentTime - this.lastPageTurnTime > this.pageTurnCooldown) {
            this.globalSoundManager.playSound(this.pageTurnSound, 'item', { volume: 1.0 });
            this.lastPageTurnTime = currentTime;
        }
        else {
            console.log('Page turn sound is on cooldown.');
        }
    }
    // Play Page Turn Sound 2 with Cooldown
    playPageTurnSound2() {
        const currentTime = this.scene.time.now;
        if (currentTime - this.lastPageTurnTime > this.pageTurnCooldown) {
            this.globalSoundManager.playSound(this.pageTurnSound2, 'item', { volume: 1.0 });
            this.lastPageTurnTime = currentTime;
        }
        else {
            console.log('Page turn sound 2 is on cooldown.');
        }
    }
    playShieldSound() {
        this.globalSoundManager.playSound(this.shieldSound, 'item', { volume: 1.0 });
    }
    playSwordSound() {
        this.globalSoundManager.playSound(this.swordSound, 'item', { volume: 1.0 });
    }
    playGrowlSound() {
        if (!this.isGrowlPlaying) {
            this.globalSoundManager.playSound(this.growlSound, 'item', { volume: 1.0 });
            // Listen for the 'complete' event on the growl sound
            this.scene.sound.once('complete', () => {
                const randomChoice = Math.random() < 0.5 ? this.minotaurisnear : this.minotaurisnear2;
                this.globalSoundManager.playSound(randomChoice, 'item', { volume: 1.0 });
            });
            this.isGrowlPlaying = true;
        }
    }
    resetGrowlSound() {
        this.isGrowlPlaying = false;
    }
    playPortalTransferSound() {
        this.globalSoundManager.playSound(this.portalTransferSound, 'item', { volume: 1.0 });
    }
    playGameOverNarration() {
        this.globalSoundManager.playSound(this.gameoverSound, 'narration', { volume: 1.0 });
    }
    playGameCompleteNarration() {
        this.globalSoundManager.playSound(this.gamecompleteSound, 'narration', { volume: 1.0 });
    }
    playLevelCompleteNarration() {
        this.globalSoundManager.playSound(this.levelCompleteNarrationSound, 'narration', { volume: 1.0 });
    }
    playPortalProximitySound() {
        if (!this.isPortalProximitySoundPlaying) {
            this.globalSoundManager.playSound(this.portalProximitySound, 'item', { volume: 0.05 });
            this.isPortalProximitySoundPlaying = true;
            this.scene.time.delayedCall(10000, () => {
                this.isPortalProximitySoundPlaying = false;
            });
        }
    }
    playKeyPickupSound() {
        this.globalSoundManager.playSound(this.keyPickupSound, 'item', { volume: 1.0 });
    }
    playMonsterLOSSound() {
        this.globalSoundManager.playSound(this.monsterLOSSound, 'item', { volume: 1.0 });
    }
    playGateOpeningSound() {
        this.globalSoundManager.playSound(this.gateOpeningSound, 'item', { volume: 1.0 });
    }
    playChestOpenSound() {
        this.globalSoundManager.playSound(this.chestOpenSound, 'item', { volume: 1.0 });
    }
    playCoinPickupSound() {
        this.globalSoundManager.playSound(this.coinPickupSound, 'item', { volume: 1.0 });
    }
    playYarnPickupSound() {
        this.globalSoundManager.playSound(this.yarnPickupSound, 'item', { volume: 1.0 });
    }
    playChaseLoopSound() {
        this.globalSoundManager.playSound(this.chaseLoopSound, 'background', { volume: 1.0 });
    }
    stopChaseLoopSound() {
        this.globalSoundManager.stopSound(this.chaseLoopSound, 'background');
    }
    stopRunningSound() {
        this.globalSoundManager.stopSound(this.runningSound, 'background');
    }
    playRandomScrollSound() {
        const randomSound = Phaser.Math.RND.pick(this.scrollSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomMapSound() {
        const randomSound = Phaser.Math.RND.pick(this.mapSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomUseYarnSound() {
        const randomSound = Phaser.Math.RND.pick(this.yarnSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomFindYarnSound() {
        const randomSound = Phaser.Math.RND.pick(this.findyarnSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomLevelSound() {
        const randomSound = Phaser.Math.RND.pick(this.levelSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomMainSound() {
        const randomSound = Phaser.Math.RND.pick(this.mainSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomTorchSound() {
        const randomSound = Phaser.Math.RND.pick(this.torchSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomKeySound() {
        const randomSound = Phaser.Math.RND.pick(this.keySounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomIdleSound() {
        const randomSound = Phaser.Math.RND.pick(this.idleSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomPotionSound() {
        const randomSound = Phaser.Math.RND.pick(this.potionSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomStoreSound() {
        const randomSound = Phaser.Math.RND.pick(this.storeSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playRandomGoldSound() {
        const randomSound = Phaser.Math.RND.pick(this.goldSounds);
        this.globalSoundManager.playSound(randomSound, 'narration', { volume: 1.0 });
    }
    playNarrationSound(key) {
        this.globalSoundManager.playSound(key, 'narration', { volume: 1.0 });
    }
    stopAllSounds() {
        this.globalSoundManager.stopAllSounds();
    }
}
