import Phaser from 'phaser';
import { TILE_SIZE, TEMP_PORTAL_COOLDOWN } from '../utils/constants';
import { shuffleArray, findDeadEnds } from '../utils/helpers'; // Import your helper functions
export default class Portals {
    scene;
    player;
    occupiedPositions;
    portals;
    portal1;
    portal2;
    cooldown = 3000; // Cooldown period in milliseconds
    lastTeleportTime = 0; // Last teleport time
    sounds;
    lastPortalProximityTime = 0;
    permanentlyVisiblePortals; // Track portals that are permanently visible
    temporaryPortalCooldown = TEMP_PORTAL_COOLDOWN; // Duration the temporary portal lasts in milliseconds
    constructor(scene, player, occupiedPositions, sounds) {
        this.scene = scene;
        this.player = player;
        this.occupiedPositions = occupiedPositions;
        this.portals = this.scene.physics.add.group();
        this.sounds = sounds;
        this.permanentlyVisiblePortals = new Set();
        player.setPortals(this); // Set the portals reference in the player class
    }
    spawnTemporaryPortal() {
        console.log("spawnTemporaryPortal called");
        const deadEnds = this.getDeadEndsInDifferentQuadrant();
        console.log("Dead ends in different quadrants:", deadEnds);
        if (deadEnds.length === 0) {
            console.log("No valid dead ends found in different quadrants.");
            return;
        }
        const randomDeadEnd = Phaser.Math.RND.pick(deadEnds);
        console.log("Selected dead end for target portal:", randomDeadEnd);
        // Spawn the portals
        const playerPortal = this.scene.physics.add.sprite(this.player.x, this.player.y, 'portal');
        const targetPortal = this.scene.physics.add.sprite(randomDeadEnd.x * TILE_SIZE, randomDeadEnd.y * TILE_SIZE, 'portal');
        console.log("Player portal spawned at:", { x: this.player.x, y: this.player.y });
        console.log("Target portal spawned at:", { x: randomDeadEnd.x * TILE_SIZE, y: randomDeadEnd.y * TILE_SIZE });
        playerPortal.setOrigin(0, 0);
        targetPortal.setOrigin(0, 0);
        this.portals.add(playerPortal);
        this.portals.add(targetPortal);
        // Add overlap logic
        this.scene.physics.add.overlap(this.player, playerPortal, () => {
            console.log("Player entered player portal, teleporting to target portal.");
            this.teleportPlayer(targetPortal);
        }, undefined, this);
        this.scene.physics.add.overlap(this.player, targetPortal, () => {
            console.log("Player entered target portal, teleporting to player portal.");
            this.teleportPlayer(playerPortal);
        }, undefined, this);
        // Remove the portals after the cooldown period
        this.scene.time.delayedCall(this.temporaryPortalCooldown, () => {
            console.log("Temporary portals destroyed after cooldown.");
            playerPortal.destroy();
            targetPortal.destroy();
        });
        this.player.disableInput();
        this.scene.time.delayedCall(500, () => {
            console.log("Player input re-enabled after portal use.");
            this.player.enableInput();
        }, [], this);
    }
    getDeadEndsInDifferentQuadrant() {
        const deadEnds = this.getDeadEnds();
        const playerQuadrant = this.getQuadrant(this.player.x / TILE_SIZE, this.player.y / TILE_SIZE);
        console.log("Player is in quadrant:", playerQuadrant);
        console.log("Dead ends found:", deadEnds);
        const deadEndsInDifferentQuadrants = deadEnds.filter(deadEnd => {
            const deadEndQuadrant = this.getQuadrant(deadEnd.x, deadEnd.y);
            console.log(`Dead end at (${deadEnd.x}, ${deadEnd.y}) is in quadrant:`, deadEndQuadrant);
            return deadEndQuadrant !== playerQuadrant;
        });
        return deadEndsInDifferentQuadrants;
    }
    getQuadrant(x, y) {
        const mazeWidth = this.scene.registry.get('maze')[0].length;
        const mazeHeight = this.scene.registry.get('maze').length;
        const halfWidth = mazeWidth / 2;
        const halfHeight = mazeHeight / 2;
        if (x < halfWidth && y < halfHeight)
            return 'top-left';
        if (x >= halfWidth && y < halfHeight)
            return 'top-right';
        if (x < halfWidth && y >= halfHeight)
            return 'bottom-left';
        return 'bottom-right';
    }
    getDeadEnds() {
        const maze = this.scene.registry.get('maze');
        return findDeadEnds(maze);
    }
    // ... existing methods ...
    addPortals(deadEnds) {
        if (deadEnds.length < 2)
            return;
        const [portal1Pos, portal2Pos] = shuffleArray(deadEnds).slice(0, 2);
        this.portal1 = this.scene.physics.add.sprite(portal1Pos.x * TILE_SIZE, portal1Pos.y * TILE_SIZE, 'portal');
        this.portal2 = this.scene.physics.add.sprite(portal2Pos.x * TILE_SIZE, portal2Pos.y * TILE_SIZE, 'portal');
        this.portal1.setOrigin(0, 0);
        this.portal2.setOrigin(0, 0);
        this.portal1.setAlpha(0); // Make portal invisible initially
        this.portal2.setAlpha(0); // Make portal invisible initially
        this.portals.add(this.portal1);
        this.portals.add(this.portal2);
        this.scene.physics.add.overlap(this.player, this.portal1, () => {
            this.teleportPlayer(this.portal2);
        }, undefined, this);
        this.scene.physics.add.overlap(this.player, this.portal2, () => {
            this.teleportPlayer(this.portal1);
        }, undefined, this);
        this.occupiedPositions.add(`${portal1Pos.x},${portal1Pos.y}`);
        this.occupiedPositions.add(`${portal2Pos.x},${portal2Pos.y}`);
    }
    getPortals() {
        return this.portals.getChildren();
    }
    teleportPlayer(targetPortal) {
        const currentTime = this.scene.time.now;
        if (currentTime - this.lastTeleportTime > this.cooldown) {
            console.log("Teleporting player to:", targetPortal);
            this.player.setPosition(targetPortal.x, targetPortal.y);
            this.lastTeleportTime = currentTime;
            this.sounds.playPortalTransferSound(); // Play the portal transfer sound
            // Disable player movement for 0.25 seconds after teleportation
            this.player.disableInput();
            this.scene.time.delayedCall(500, () => {
                this.player.enableInput();
            }, [], this);
        }
    }
    update() {
        // Check proximity for portal sound
        const currentTime = this.scene.time.now;
        this.portals.getChildren().forEach((portal) => {
            const portalSprite = portal;
            const portalDistance = Phaser.Math.Distance.Between(this.player.x, this.player.y, portalSprite.x, portalSprite.y);
            if (portalDistance < TILE_SIZE * 3 && portalDistance > TILE_SIZE && currentTime - this.lastPortalProximityTime > 10000) {
                this.sounds.playPortalProximitySound();
                this.lastPortalProximityTime = currentTime;
            }
        });
    }
    updateVisibility(fovTiles) {
        const updateObjectVisibility = (object) => {
            if (object) {
                const tileX = Math.floor(object.x / TILE_SIZE);
                const tileY = Math.floor(object.y / TILE_SIZE);
                const portalKey = `${tileX},${tileY}`;
                if (this.player.hasMagicScroll && fovTiles[tileY] && fovTiles[tileY][tileX]) {
                    this.permanentlyVisiblePortals.add(portalKey);
                    object.setAlpha(1);
                }
                else if (fovTiles[tileY] && fovTiles[tileY][tileX]) {
                    object.setAlpha(1);
                }
                else if (this.permanentlyVisiblePortals.has(portalKey)) {
                    object.setAlpha(1);
                }
                else {
                    object.setAlpha(0);
                }
            }
        };
        this.getPortals().forEach((portal) => {
            updateObjectVisibility(portal);
        });
    }
}
